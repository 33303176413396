import { Box, Button, Checkbox, Chip, Divider, FormControlLabel, FormGroup, IconButton, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {FiPlusCircle} from 'react-icons/fi'
import CancelIcon from "@mui/icons-material/Cancel";

export const RegexGeneration = ({regex,setRegex, finalMessageRegex,setFinalMessageRegex, children}) => {

  const [finalRegex,setFinalRegex] = useState([])

  const [step,setStep] = useState('Start')

  const [start, setStart] = useState({
    text:[],
    number:false,
    minuscule:false,
    majuscule:false,
    speciaux:false,
    min:'',
    max:'',
    indefinite:false
  })

  const [tempText,setTempText] = useState('')
  //for waiting react useState
  const [actionStatus, setActionStatus] = useState(false)

  const [optionCheck, setOptionCheck] = useState(false)

  const handlechangeCheckBox = (e) => {
   /* if(start.number||start.minuscule||start.majuscule||start.speciaux){
      setOptionText(true)
    } else {
      setOptionText(false)
    }
*/
    setStart({
      ...start,
      [e.target.name]: e.target.checked
    })
  }

  const addTextOrOption = () =>{
    if(tempText.trim() !== '')
    {
      setStart(prev => ({
      ...prev,
      text: [...prev.text,tempText]
    }))
    }

    setTempText('')
  }
  const removeTextOrOption = (item) =>{
    setStart(prev => ({
      ...prev,
      text: prev.text.filter(i => i!== item)
    }))
  }

  const handlechangeTextField = (e) => {
    setTempText(e.target.value)

    /*setStart({
      ...start,
      text: start.text.push(e.target.value)
    })
    */
    
  }

  useEffect(()=>{
    //console.log('len',start.text.length,tempText)
    if(tempText !== '' || start.text.length !== 0 ){
      setOptionCheck(true)

    } else{
      setOptionCheck(false)
    }
  },[tempText,start])

  //const promisedsetStart = (newState) => new Promise(resolve => setStart(newState,resolve))

  const handleNextStep = async (step) =>{

      //console.log("tt",tempText)
      
      //await promisedsetStart('feno')
      console.log(start)
     if(tempText.trim() !== '')
      {
        setActionStatus(true)

        //console.log('miditra')
        await new Promise(resolve => {
          
          setStart(prev => {
            resolve();
            return {
            ...prev,
            text: [...prev.text,tempText]
            } 
          })
      })
      //console.log('vite', start)
      } 

     setStep(step)
     //setTempText('')

     if(tempText.trim() === '') {
        setFinalRegex(prev => [...prev,start])
        //console.log('trac')
        setStart({
        text:[],
        number:false,
        minuscule:false,
        majuscule:false,
        speciaux:false,
        min:'',
        max:'',
        indefinite:false
      })
      setOptionCheck(false)
     }
    console.log(finalRegex)
  }
  // useEffect for set state
  useEffect (()=>{
    if(actionStatus === true && tempText !== '' && start.text.find(i => i == tempText)){
      setFinalRegex(prev => [...prev,start])
      setStart({
        text:[],
        number:false,
        minuscule:false,
        majuscule:false,
        speciaux:false,
        min:'',
        max:'',
        indefinite:false
      })
      setOptionCheck(false)
      setTempText('')
      setActionStatus(false)
    }
  },[start,finalRegex,tempText])

  const handleEndStep = () =>{
    let message = ''
    let regexTemp=''
    //console.log('testf',finalRegex)
    //console.log(finalRegex.length)
    for(let i=0;i<finalRegex.length;i++){
      if(finalRegex[i].text.length !== 0){
        //console.log('if')
        regexTemp=regexTemp+'('

        for(let j=0;j<finalRegex[i].text.length;j++){
          regexTemp= regexTemp+finalRegex[i].text[j]
          message = message+finalRegex[i].text[j]
          if (j !== finalRegex[i].text.length-1){
            regexTemp= regexTemp+'|'
            message = message+' ou '
          }
        }


        regexTemp=regexTemp+')'
      }
      else {
        //console.log('else')
        let a=''
        if(finalRegex[i].number){
          a=a+'0-9'
          message = message+'chiffre, '
        }
        if(finalRegex[i].minuscule){
          a=a+'a-z'
          message = message+'charactere minuscule, '
        }
        if(finalRegex[i].majuscule){
          a=a+'A-Z'
          message = message+'caractere majuscule, '
        }
        if(finalRegex[i].speciaux){
          a=a+"_!#@$%&'*+\/=?`{|}~^.-"
          message = message+'caractere speciaux, '
        }
        message = message+'de longueur '

        if(finalRegex[i].min===finalRegex[i].max){
          a='['+a+']{'+finalRegex[i].min+'}'
          message = message+' exactement '+finalRegex[i].min
        } else {
          a='['+a+']{'+finalRegex[i].min+','
          message = message+' min = '+finalRegex[i].min

          if(!finalRegex[i].indefinite){
            a=a+finalRegex[i].max+'}'
            message = message+' et max = '+finalRegex[i].max
          }else {
            a=a+'}'
            message = message+' et max indeterminer'
          }
        }
        //setRegex(a+'$')
        regexTemp=regexTemp+a
      }

      if(i===0){
        regexTemp='(^'+regexTemp
        message = 'Commence par '+message
      }
      
      if(i===finalRegex.length-1){
        regexTemp=regexTemp+'$)'
      } else {
      message = message+', suivi de '
      }
  }

  setFinalMessageRegex(message)
  setRegex(regexTemp)
  setStep('end')

  console.log(regexTemp)

}

  

  const handlechangeTextFieldMinAndMAx = (e) =>{
    setStart({
      ...start,
      [e.target.name]: e.target.value
    })
  }

  const [testRegex, setTestRegex] = useState('')
  const [isValid, setValid] = useState(true)
  const handleTestRegex = (e) =>{
    setTestRegex(e.target.value)
    const r= new RegExp(regex)
    setValid(r.test(e.target.value))
  } 

  return (
    <Box height={450} display={'flex'} flexDirection={'column'} justifyContent={'center'} alignItems={'center'}>
    { (step === 'Start'||step === 'Plus') &&
    <>
    <Box sx={{
        //display:'flex'
    }}>
        <Box>
            <Typography sx={{mb:1}}>{step}</Typography>
            <Box>
              <Paper sx={{py:2,px:1, bgcolor:'#00000088', boxShadow:'none' }}>
                <Box display={'flex'} flexDirection={'column'} >
                  <Box>
                    {start.text.map((item,index) => 
                    <Chip 
                      key={index} 
                      label={item} 
                      sx={{m:0.3}}
                      onDelete={() => {
                        removeTextOrOption(item)
                      }}
                      deleteIcon={
                        <CancelIcon
                          onMouseDown={(event) => event.stopPropagation()}
                        />}
                      /> 
                    )}
                  </Box>
                <Box sx={{mt:0.5}}>
                  <TextField value={tempText} size='small' onChange={handlechangeTextField}/>
                  <IconButton onClick={addTextOrOption}>
                    <FiPlusCircle/>
                  </IconButton>
                </Box>
                </Box>{/* End flex column */}
              </Paper>
            </Box>
            <Divider sx={{my:0.5}}>Ou</Divider>
            <Paper sx={{py:2,px:1, bgcolor:'#00000088', boxShadow:'none' }}>
            <Box sx={{
              display:'flex'
            }}>
              <FormGroup>
              <FormControlLabel control={<Checkbox onChange={handlechangeCheckBox} name='number' size='small' checked={start.number}/>}  label='Chiffre' disabled={optionCheck}/>
                <FormControlLabel control={<Checkbox onChange={handlechangeCheckBox} name='minuscule' size='small' checked={start.minuscule}/>}  label='Charactere Minuscule' disabled={optionCheck}/>
                <FormControlLabel control={<Checkbox onChange={handlechangeCheckBox} name='majuscule' size='small' checked={start.majuscule}/>} label='Charactere Majuscule' disabled={optionCheck}/>
                <FormControlLabel control={<Checkbox onChange={handlechangeCheckBox} name='speciaux' size='small' checked={start.speciaux}/>} label='Charactere speciaux' disabled={optionCheck}/>
              </FormGroup>
              <Box>
                <TextField label='min' name='min' size='small' value={start.min} onChange={handlechangeTextFieldMinAndMAx}  disabled={optionCheck}/>
                <Box sx={{
                    display:'flex',
                    my:1
                  }}>
                    <TextField label='max' name='max' size='small' sx={{mr:1}} value={start.max} onChange={handlechangeTextFieldMinAndMAx} disabled={optionCheck}/>
                  <FormControlLabel control={<Checkbox onChange={handlechangeCheckBox} size='small' name='indefinite' checked={start.indefinite}/>} label='Infinie'  disabled={optionCheck}/>
                </Box>
              </Box>
            </Box>
            </Paper>
        </Box>
    </Box>
    <Button variant='contained' sx={{width:'100%', mt:2 }} onClick={()=>handleNextStep('pause')}>Suivant</Button>
    </>
    }

    {
      step === 'pause' && (
        <>
          <Box sx={{display:'flex', flexDirection:'column', width:'fit-content'}}>
            <Button variant='outlined' sx={{m:0.5}} onClick={()=> setStep('Plus')}>Ajouter</Button>
            <Button variant='outlined'  sx={{m:0.5}} onClick={handleEndStep}>Terminer</Button>
          </Box>
        </>
      )
    }

    { step === 'end' && (
      <Stack sx={{width:'100%'}} spacing={2}>
      <Typography>{finalMessageRegex}</Typography>
      <TextField label={'test le'} value={testRegex} onChange={handleTestRegex} error={!isValid}/>
      {children}
      </Stack>

    )
    }
    </Box>
  )
}
