import { Button, CircularProgress, Container, Grid, Paper, Stack } from '@mui/material'
import React, { useState } from 'react'
import { Title } from '../component/admin'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'

import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { axiosClient, backendUrl} from '../utils'

import { SnackBarStatus } from '../component'
import { FormSelectImage, MultipleSelect } from '../component/form'


export const NewTrade = () => {
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
      ];
    
    const results = useQueries({ queries })
    
    const [wallets] = results

    
  return (
    <div>{(
         wallets.isLoading
       || wallets.data.data === undefined
       ) && <TwoBarContentLoader/>}
   
     { wallets.isSuccess
       && wallets.data.data !== undefined
       && 
     <NewTradeLayout 
       wallets={wallets.data.data} 
       />
     }
    </div>
  )
}

const NewTradeLayout = ({wallets}) => {
    console.log(wallets)

  const [isLoading, setIsLoading]= useState(false)
  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)



    const dataForm = new FormData()

  const validationSchema = Yup.object().shape({
      baseWallet : Yup.string()
        .required("Veillez ajouter un nom d'utilisateur"),
      compatibleWallet: Yup.array().min(1, 'Select at least one payment method').required('Payment method is required'),
  })

  const methods = useForm({
      resolver:yupResolver(validationSchema),
      defaultValues: {
          baseWallet: '',
          compatibleWallet: [],
        },
    })

    
  const {handleSubmit, formState: { errors }} = methods



  const onSubmit = (data) => {
       // console.log('miditra')
       setIsLoading(true)
     dataForm.append('baseWallet',data.baseWallet)
     data.compatibleWallet.forEach((id,index)=>{
         dataForm.append(`compatibleWallet[${index}]`,id)
       })
 
     axios.post(backendUrl+'trade',dataForm,
     {
       headers : {
             'content-type' : 'multipart/form-data',
         },
       transformRequest: dataForm=> dataForm,//to solve axios issue
     })
     .then(resp => {
         setSnackbar({children:'Creation de nouveau baseWallet avec success', severity: 'success'})
         setIsLoading(false)
         setStatus(true)
       
   })
   .catch(error => {
         setIsLoading(false)
         setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
         setStatus(true)
   })
    }

    return (
        <>
        <Container>
        <Title title={"Ajout de nouveau Actif de base"}/>
        <Grid container sx={{mt:3}}>
            <Grid item  xs={12} md={6}>
            <Paper elevation={1} sx={{
                    //width:{xs:'95%', md:'80%'},
                    height:'fit-content',
                    p:{xs:2,sm:4,md:6},
                    boxShadow:6,
                    backgroundColor:'#1e1e1ebb'
            }}>
                   <FormProvider {...methods}>
                    <form>
                        <Stack spacing={1}>
                            <FormSelectImage name='baseWallet' label={'Actif de Base'} options={wallets} error={!!errors?.baseWallet} helpertext={errors?.baseWallet?.message} fullWidth/>
                            <MultipleSelect name='compatibleWallet' label={'Les actifs Compatible'} options={wallets} error={!!errors?.compatibleWallet} helpertext={errors?.compatibleWallet?.message} fullWidth/>
                            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                            { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Ajouter"}
                            </Button>
                        </Stack>
                    </form>
                    </FormProvider>
            </Paper>
            </Grid>
            </Grid>
        </Container>
        <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
        </>
    )
  }