import { Box, Button, Container, Grid, IconButton, Paper, Stack,  Typography } from '@mui/material'
import React from 'react'
import { Title } from '../component/admin'
import { axiosClient, backendUrl } from '../utils'
import { TwoBar } from '../component/contentLoading'
import { useQueries, useQuery } from '@tanstack/react-query'
import { useNavigate } from 'react-router-dom'
import { TbCirclePlus } from 'react-icons/tb'
import { MdEdit } from 'react-icons/md'

export const Category = () => {

  const queries = [
    {
      queryKey: ['category'],
      queryFn: () => axiosClient.get('category'),
    },
    {
      queryKey: ['allPaymentMethodInCategory'],
      queryFn: () => axiosClient.get('paymentIntoCategory'),
    },
    // Add more queries as needed
  ];

const results = useQueries({ queries })

const [categories ,allPaymentMethodInCategory] = results
 return (
  <>
  {(
    categories.isLoading 
    || allPaymentMethodInCategory.isLoading
    || allPaymentMethodInCategory.data.data === undefined)
    && <TwoBar/>}
  {
    categories.isSuccess 
    && categories.data.data !== undefined 
    && allPaymentMethodInCategory.isSuccess 
    && allPaymentMethodInCategory.data.data !== undefined 
    && <CategoryLayout categories={categories.data.data} allPaymentMethodInCategory={allPaymentMethodInCategory.data.data }/>}
  </>
 )
 
}

const CategoryLayout = ({categories,allPaymentMethodInCategory}) => {

  const navigate = useNavigate()


  return (
    <Container>
    <Box>
      {/* List des categories */}
      <Title title={'Les Categories'}/>
      <Grid container sx={{mt:3}}>
        <Grid item  xs={12} md={6}>
        <Button variant='outlined' startIcon={<TbCirclePlus />} onClick={()=>navigate('new')}>
            Nouveau
          </Button>
            {/* Header */}
            <Box sx={{
              display: {xs:'flex',},//sm:'none'
              flexDirection: 'row',
              width: '100%',
              padding: 1,
              justifyContent:'center'
              //backgroundColor: '#f0f0f0'
            }}>
                  <Typography sx= {{ width:'25%'}}>Nom</Typography>
                  <Typography sx= {{ width:'65%', }}>Mode de paiement</Typography>
                  <Box sx= {{ width:'10%', }}></Box>
            </Box>

                    {/* END Header */}  

        <Stack spacing={1}>
        {
          categories.map((item,index)=>{
            const category = allPaymentMethodInCategory.find(w => w.categoryId == item.id)
            
            return (
              <Paper key={index} sx={{
                display: {xs:'flex'}, //,sm:'none'
                flexDirection: 'row',
                width: '100%',
                padding: 1,
                my:1,
            
                //borderBottom: '1px solid #ccc',
                alignItems: 'center',
                justifyContent:'center'
            }}>
            
                <Box sx={{
                display:'flex',
                width:'25%',  
                }}>
                <Typography sx={{ml:2}}>{item.name}</Typography>
                </Box>

                <Box sx= {{ width:'65%',display:'flex', flexWrap:'wrap'}}>
                    {
                        category.paymentMethod.map((value,index)=>{   
                        return (
                          <Box key={index} sx={{m:0.5}}>
                          <img src={backendUrl+'image/logo/'+value.icon} width={30} height={30}/>
                          </Box>
                        )
                        })
                    }
                </Box>
                <Box sx= {{ width:'10%', display:'flex',justifyContent:'center' }}>
                <IconButton onClick={()=>navigate('edit/'+item.id)}>
                    <MdEdit />
                </IconButton>
                </Box>
            </Paper>)
      
          })
        }
        </Stack>
      
        </Grid>
      </Grid>
      {/* End Liste des categories */}

      
    </Box>
    </Container>
  )

}