import React, { useState } from 'react'

import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'


import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import { Button, useScrollTrigger } from '@mui/material'
import { Link } from 'react-router-dom'
import { useAuth } from '../auth'
import { pages } from '../utils'



export const Header = () => {


    const auth = useAuth()

    const [anchorElNav, setAnchorElNav] = useState(false)

    
    
    const handleCloseNavMenu = () => {
        setAnchorElNav(false);
    }

    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
        target: window ? window : undefined,     ///#282C34 #121212
    })
    
  return (
        <AppBar sx={{backgroundColor:trigger ? '#1c1c1c':'#1c1c1c' }} elevation={0}>
          <Container >
            <Toolbar disableGutters>
              
                <Box sx={{
                    display: {xs:'none',md:'flex'},
                    flexDirection: 'column',
                    justifyContent: 'center',
                    ml:2
                }}>
                    <Typography align={'center'} variant='h6' sx={{fontWeight:'bold', fontFanily:'Mono'}} width={40} height={40}>
                        MVX
                    </Typography>
                </Box>

                {/* START MOBILE VIEW */}
               
                
                {/* END MOBILE VIEW */}

                {/* START LARGE VIEW */}

                <Box sx={{ flexGrow: 1 }}>
            
                </Box>
                <Box sx={{ flexGrow: 0, display: { xs: 'none', md: 'flex' } }}>
                    {pages.map((page) => (
                    <Link
                        key={page.title}
                        onClick={handleCloseNavMenu}
                        style={{ my: 2, color: 'white', display: 'block' , cursor:'pointer',textDecoration: 'none',}}
                        to={page.link}
                    >
                        <Typography variant='h6' sx={{
                            mx:1, 
                            fontSize: 18,
                            fontWeight: 200,
                            color: 'inherit',
                    }}>{page.title.toUpperCase()}</Typography>
                    </Link>
                    ))}
                </Box>
                <Button variant='contained' sx={{ ml:3 }} onClick={auth.handleLogout}>Deconnexion</Button>

                {/* END LARGE VIEW */}
            </Toolbar>
        </Container>
        </AppBar>
  )
}