export const backendUrl = "https://test.bitjoycoin.com:83/"//'https://127.0.0.1:8000/'//"https://test.bitjoycoin.com:83/"//'https://127.0.0.1:8000/'//'https://127.0.0.1:8000/'//"https://test.bitjoycoin.com:83/"//'https://127.0.0.1:8000/'///'https://127.0.0.1:8000/'/////"https://mvxchain.osc-fr1.scalingo.io"//'https://127.0.0.1:83/'//'http://65.21.49.171/'//'https://127.0.0.1:8000/'//https://mvxchain.osc-fr1.scalingo.io//
export const socketUrl = "wss://test.bitjoycoin.com:8080"//"ws://localhost:3001"////"wss://test.bitjoycoin.com:8080"//"ws://localhost:3001"//"ws://localhost:3001"//ws://test.bitjoycoin.com:3001

export const pages = [
    {title:'Home',link:'/', offset: 0},
    {title:'Cotation',link:"/cotation", offset: -80},
    {title:'Trade',link:"/trade", offset: -80},
    {title:'Extra',link:"/extra", offset: -80},
    {title:'Portefeuille',link:"/wallet", offset: -80},
]
