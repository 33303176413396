import { Box, FormControl, InputLabel, MenuItem, Select, Typography } from '@mui/material'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { backendUrl } from '../../utils'

const MuiSelect = React.forwardRef((props, ref) => {
    const {label,name, options}= props

    return (
        <FormControl {...props}>
          <InputLabel id={name}>{label}</InputLabel>
            <Select 
              labelId={name} 
              label={label} 
              ref={ref} // Forward the ref here
              {...props}>
                {options.map((item,index)=>(
                    <MenuItem key={index} value={item.id}>
                      <Box display={'flex'}>
                        <img src={backendUrl+'image/logo/'+item.icon} width={30} height={30}/>
                        <Typography sx={{ml:2}}>{item.name}</Typography>
                      </Box>
                    </MenuItem>
                ))}
            </Select>
          </FormControl>
    )
})

export const FormSelectImage = (props) => {

    const {control} = useFormContext()
    const {name,label} = props
  return (
    <Controller
    render={({field})=>(
        <MuiSelect {...props} {...field}/>
    )}
    control={control}
    name={name}/>
  )
}