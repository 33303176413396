import { useQueries } from '@tanstack/react-query';
import React, { useState } from 'react'
import { axiosClient, backendUrl } from '../utils';
import { TwoBar } from '../component/contentLoading';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, CircularProgress, Divider, Grid, IconButton, Paper, Stack, TextField, Typography } from '@mui/material';
import { SnackBarStatus } from '../component';
import { MdEdit } from 'react-icons/md';
import { Title } from '../component/admin';

export const EditCategory = () => {
    const queries = [
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['allPaymentMethodInCategory'],
          queryFn: () => axiosClient.get('paymentIntoCategory'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [categories ,allPaymentMethodInCategory] = results
     return (
      <>
      {(
        categories.isLoading 
        || allPaymentMethodInCategory.isLoading
        || allPaymentMethodInCategory.data.data === undefined)
        && <TwoBar/>}
      {
        categories.isSuccess 
        && categories.data.data !== undefined 
        && allPaymentMethodInCategory.isSuccess 
        && allPaymentMethodInCategory.data.data !== undefined 
        && <EditCategoryLayout categories={categories.data.data} categoriesObject={categories} allPaymentMethodInCategory={allPaymentMethodInCategory.data.data }/>}
      </>
     )
     
    }
    
    const EditCategoryLayout = ({categories,allPaymentMethodInCategory, categoriesObject}) => {
    
        const { id } = useParams()

        const category = categories.find(w => w.id == id)

        const categoryPaymentMethod = allPaymentMethodInCategory.find(w => w.categoryId == id)

           //serie ana useSatet edit
  const [isNameEdited,setNameEdited] = useState(false)
  const [isNameEditedLoading,setNameEditedLoading] = useState(false)


  const [nameEditedValue,setNameEditedValue] = useState(category.name)

  //snacbar

  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  const sendChange = (identifier,value,setEdited,setEditLoading) => {
    const dataForm = new FormData()
    dataForm.append('identifier',identifier)

    if(identifier==='paymentMethod'){
      value.forEach((element,index) => {
        dataForm.append(`value[${index}]`,element)
      });
    }
    else {
      dataForm.append('value',value)
    }

    axios.post(backendUrl+'category/'+id,dataForm,
      {
        headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:`Modification du ${identifier} avec success`, severity: 'success'})
      setEdited(false)
      setEditLoading(false)
      setStatus(true)
      categoriesObject.refetch()
    })
    .catch(e=>{
      setSnackbar({children:`Erreur sur la modification du ${identifier}`, severity: 'error'})
      setEditLoading(false)
      setStatus(true)
      console.log(e)
    })
  }

  const handleChangeWallet = (identifier) => {
    console.log(identifier)
    if (identifier === 'name'){
      setNameEditedLoading(true)
      sendChange(identifier,nameEditedValue,setNameEdited,setNameEditedLoading)
    }

  }

      return (<>
      <Title title={"LE CATEGORY "+category.name}/>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          p:{xs:1,md:3}
        }}>
          <Stack spacing={1} sx={{px:2}}>
            {/* Name */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isNameEdited ?'white':'grey'}}>Nom</Typography>
                {!isNameEdited && <Typography>{category.name}</Typography>}
              </Box>
                {!isNameEdited && <IconButton onClick={()=>setNameEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isNameEdited &&
            <>
            <TextField value={nameEditedValue} onChange={(e)=> setNameEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNameEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('name')} variant='contained' sx={{width:100}}>{isNameEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Name */}

            {/* Payment Method */}
            <Typography sx={{color: isNameEdited ?'white':'grey'}}>Mode de Paiement</Typography>
            <Box sx={{display:'flex', flexWrap:'wrap'}}>
                {
                    categoryPaymentMethod.paymentMethod.map((value,index)=>{   
                    return (
                        <Box key={index} sx={{m:0.5}}>
                        <img src={backendUrl+'image/logo/'+value.icon} width={30} height={30}/>
                        </Box>
                    )
                    })
                }
            </Box>
            {/* ENDPayment Method */}

            
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
      </>)
}
