import { Typography } from '@mui/material'
import React from 'react'

export const Title = ({title}) => {
  return (
    <Typography variant='h6' color={'primary'} sx={{
        fontWeight:'bold',
        textTransform:'uppercase',
        py:1
    }}>{title}</Typography>
  )
}
