import { Box, Button, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Title } from '../component/admin'
import { TbCirclePlus } from "react-icons/tb"
import { useQueries } from '@tanstack/react-query'
import { axiosClient, backendUrl } from '../utils'
import { TwoBarContentLoader } from '../component/contentLoading'
import { MdEdit } from 'react-icons/md'


export const TradeAdmin = () => {
  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['trade'],
      queryFn: () => axiosClient.get('trade'),
    },
  ];

  const results = useQueries({ queries })

  const [wallets,trade] = results

  return (
    <div>{(
      wallets.isLoading
    || wallets.data.data === undefined
    || trade.isLoading
    || trade.data.data === undefined
    
    ) && <TwoBarContentLoader/>}

  { wallets.isSuccess
    && wallets.data.data !== undefined
    && trade.isSuccess
    && trade.data.data !== undefined
    && 
  <TradeAdminLayout 
    wallets={wallets.data.data} 
    trade={trade.data.data}
    />
  }
 </div>
  )
}

const TradeAdminLayout = ({wallets,trade}) => {
    const navigate = useNavigate()

    console.log(trade)

    return (
        <Container >
        {/* Liste wallet*/}
        <Title title={'LES Trade'}/>
        <Grid container mt={3}>
          <Grid item xs={12} md={7}>
            <Button variant='outlined' startIcon={<TbCirclePlus />} onClick={()=>navigate('new')}>
              Nouveau
            </Button>


            {/* Header */}
            <Box sx={{
              display: {xs:'flex',},//sm:'none'
              flexDirection: 'row',
              width: '100%',
              padding: 1,
              justifyContent:'center'
              //backgroundColor: '#f0f0f0'
            }}>
                  <Typography sx= {{ width:'35%'}}>Actif de base</Typography>
                  <Typography sx= {{ width:'55%', }}>Les actifs compatibles</Typography>
                  <Box sx= {{ width:'10%', }}></Box>
            </Box>

            {/* END Header */}  

            <Stack spacing={1}>
        {
          trade.map((item,index)=>{
            //const category = allPaymentMethodInCategory.find(w => w.categoryId == item.id)
            
            return (
              <Paper key={index} sx={{
                display: {xs:'flex'}, //,sm:'none'
                flexDirection: 'row',
                width: '100%',
                padding: 1,
                my:1,
            
                //borderBottom: '1px solid #ccc',
                alignItems: 'center',
                justifyContent:'center'
            }}>
            
                <Box sx={{
                display:'flex',
                width:'40%',  
                }}>

                <img src={backendUrl+'image/logo/'+item.icon} width={30} height={30}/>
                <Typography sx={{ml:2}}>{item.name}</Typography>
                </Box>

                <Box sx= {{ width:'55%',display:'flex', flexWrap:'wrap'}}>
                    {
                        item.compatibleWallet.slice(0, 4).map((value,index)=>{   
                        return (
                          <Box key={index} sx={{m:0.5, display:'flex', alignItems:'center'}}>
                          <img src={backendUrl+'image/logo/'+value.icon} width={30} height={30}/>
                          </Box>
                        )
                        })
                    }
                </Box>
                <Box sx= {{ width:'10%', display:'flex',justifyContent:'center' }}>
                <IconButton onClick={()=>navigate('edit/'+item.id)}>
                    <MdEdit />
                </IconButton>
                </Box>
            </Paper>)
      
          })
        }
        </Stack>


        </Grid>
        </Grid>
        </Container>
    )
  }
