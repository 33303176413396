import { Box, Button, Container, FormControl, IconButton, MenuItem, Paper, Select, Stack, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { axiosClient, useAxios } from '../utils'
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { BottomDrawer } from '../component/menu';
import { useSearchParams } from 'react-router-dom';
import { IoMdArrowDropdown } from "react-icons/io";
import { FaStar } from "react-icons/fa6"
import { CiStar } from "react-icons/ci"

export const Trade = () => {
  
  const axiosInstance = useAxios()


  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['trade'],
      queryFn: () =>   axiosInstance.get('/api/trade'),
      //axiosClient.get('trade'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets,trade] = results

  return (
    <>
    {( wallets.isLoading
      || wallets.data.data === undefined
      || trade.isLoading
      || trade.data.data === undefined
      ) && <TwoBarContentLoader/>}

    { wallets.isSuccess
      && wallets.data.data !== undefined
      && trade.isSuccess
      && trade.data.data !== undefined
      && 
      <TradeLayout wallets={wallets.data.data} trade={trade.data.data} tradeObj = {trade}/> 
    }
    </>
  )
}

const TradeLayout = ({wallets,trade, tradeObj}) => {

  const axiosInstance = useAxios()

  //for bottom drawer
  const [state, setState] = useState(false)

  const DEFAULTCRYPTO = 1

  //const walletsWithoutNational = wallets.filter(item => item.categoryId !== 2)

 // const { id } = useParams()

  const [searchParams, setSearchParams] = useSearchParams();

  // Access the current 'id' value from the query parameters
  let id = searchParams.get('vr'); 

  console.log('id',id)
  const changeId = (newId) => {
    setSearchParams({ vr: newId }); // Update the 'id' parameter in the URL
  }

  if (id === null){
    console.log('id inside',id)
    changeId(DEFAULTCRYPTO)
  }


  const [crypto, setCrypto] = useState(1)

  useEffect(()=>{
  if (id !== null ){
    setCrypto(id)
  } else {
    setCrypto(DEFAULTCRYPTO)
  }
  //get current crypto function
},[])


  const getCurrentCrypto= () => trade
  .map((item) => {
    const wallet = item.compatibleWallet.find((wallet) => wallet.tradeId == crypto);
    if (wallet) {
      return {
        id: item.id,
        name: item.name,
        symbol: item.symbol,
        icon: item.icon,
        compatibleWallet: wallet,
      };
    }
    return null;
  })
  .find((item) => item !== null);


  const [currentTrade, setCurrentTrade] = useState({})

  //default crypto 
  useEffect(()=>{
    const res = getCurrentCrypto()
    console.log('mety', res)

    setCurrentTrade(res)

  },[])

  useEffect(()=>{
    //console.log(trade)
    //id=crypto
    changeId(crypto)
    const result = getCurrentCrypto()
    //console.log('result',result)
    setCurrentTrade(result)


  },[crypto,changeId])

  //method to set favorite
  const setFavoriteTrade = () => {
    axiosInstance.post('/api/tradeFavorite/'+currentTrade.compatibleWallet.tradeId)
    .then((resp)=>{tradeObj.refetch()})
    .catch((e)=>{})
  }

return (
  <>
  <BottomDrawer trade={trade} tradeObj={tradeObj} setCrypto={setCrypto} state={state} setState={setState}/>
  { Object.keys(currentTrade).length > 0 && <Container sx={{ display:'flex',justifyContent:'center'}}>
    
      <Paper sx={{width:{xs:'100%',md:500}, px:{xs:1,md:6}, pb:3, pt:2}}>
          <Stack alignItems={'center'} spacing={1} sx={{}}>
            {/* head of trade */}
            <Box sx={{ width:'100%', display:'flex', justifyContent:'space-between', alignItems:'center'}}>
                <Box 
                onClick={()=>{setState(true)}}
                sx={{
                  width:'fit-content', 
                  display:'flex',
                  alignItems:'center', 
                  p:1, 
                  mb:1, 
                  borderRadius:1,
                  '&:hover':{
                    backgroundColor:'#ffffff11',
                    cursor:'pointer' 
                  } }}>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                        
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.compatibleWallet.symbol}</Typography>
                    </Box>
                    <Typography variant='h5' sx={{fontWeight:''}}>/</Typography>
                    <Box sx={{display:'flex', alignItems:'center',justifyContent:'center'}}>
                       
                        <Typography sx={{ml:0.5, fontSize:21}}>{currentTrade.symbol}</Typography>
                    </Box>
                    <Box sx={{pr:0.5,pl:1,display:'flex',alignItems:'center'}}>
                      <IoMdArrowDropdown size={25}/>
                    </Box>
                </Box>
                {/* favorite buttton  */}
                <IconButton onClick={setFavoriteTrade}>
                  {currentTrade.compatibleWallet.favorite ? <FaStar size={23} color='yellow'/> : <CiStar size={25}/>}
                </IconButton>
                {/* end favorite buttton */}

            </Box>
            {/* END head of trade */}

              {/* bottom trade */}
              <Box sx={{display:'flex', width:'100%'}}>
                  <Button variant='outlined' sx={{width:'100%', mr:0.5}}>Acheter</Button>
                  <Button variant='outlined' sx={{width:'100%', ml:0.5}}>Vendre</Button>
              </Box>
              {/* END bottom trade */}
              <Box sx={{
                bgcolor:'#292828',
                width:'100%',
                display:'flex',
                justifyContent:'center',
                p:1.5
              }}>
                <Typography>Prix: 4600 AR</Typography>
              </Box>
              <TextField label="Combient de USDT voulez vous acheter?" fullWidth/>
              <TextField label="Total que vous allez payer en Ariary" fullWidth/>
              <Button variant='contained' sx={{width:'100%'}}>Acheter</Button>
          </Stack>
      </Paper>
  </Container>}
  </>
)
}
