import { useQueries } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react'
import { axiosClient, backendUrl } from '../utils';
import { TwoBarContentLoader } from '../component/contentLoading';
import { useParams } from 'react-router-dom';
import { Box, Button, Stack, Typography } from '@mui/material'

import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
//import axios from 'axios'
import { FormInput, FormSelectImage } from '../component/form';

export const RetraitTransaction = () => {
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,categories,paymentMethod] = results
    
    
    return(
      <>
      {(
         categories.isLoading 
        || categories.data.data === undefined 
        || wallets.isLoading
        || wallets.data.data === undefined
        || paymentMethod.isLoading
        || paymentMethod.data.data === undefined
        ) && <TwoBarContentLoader/>}
    
      {categories.isSuccess 
        && categories.data.data !== undefined  
        && wallets.isSuccess
        && wallets.data.data !== undefined
        && paymentMethod.isSuccess
        && paymentMethod.data.data !== undefined
        && 
      <RetraitTransactionLayout 
        wallets={wallets.data.data} 
        categories={categories.data.data}
        paymentMethodData={paymentMethod.data.data}
        />
      }
      </>
  )
}

const RetraitTransactionLayout = ({categories, wallets, paymentMethodData}) => {
        
    const { id } = useParams()
    const wallet = wallets.find(w => w.id == id)
    const filteredPm = paymentMethodData.filter(payment => wallet.paymentMethod.includes(payment.id));

    const [regexPaymentMethod, setRegexPaymentMethod] = useState('feno')
    const [notationFormat,setNotationFormat] = useState('feno')

    const dataForm = new FormData()

    const validationSchema = Yup.object().shape({
      solde : Yup.number()
          .typeError("Veuillez entrer un chiffre")
          .required("Veillez ajouter un nombre"),
      compteNumber: Yup.string()
      .required("Veillez entrer un numero de compte")
      .matches(new RegExp(regexPaymentMethod),`Veuillez entrer ${notationFormat} valide`),
      paymentMethod: Yup.string().required('Payment method is required'),
    })
  
    const methods = useForm({
        resolver:yupResolver(validationSchema),
        defaultValues: {
            name: '',
            paymentMethod: undefined,
          },
      })
  
      
    const {handleSubmit,watch,formState: { errors }} = methods
  
    const selectedCategory = watch('paymentMethod')

    useEffect(()=>{
      console.log('s',selectedCategory)
      if(selectedCategory !== undefined){
        const pm = filteredPm.find(w => w.id == selectedCategory)
        //console.log(pm)
        setRegexPaymentMethod(pm.format)
        setNotationFormat(pm.notationFormat)
      }
    },[selectedCategory])

      const onSubmit = (data) => {
       // setIsLoading(true)
       //console.log(data)
  
       
        
      dataForm.append('paymentMethod',data.name)
      dataForm.append('solde',data.name)
      dataForm.append('compteNumber',data.compteNumber)
      
      alert('mety')
     /* axios.post(ENDPOINT,dataForm,
      {
          headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm => dataForm,//to solve axios issue
      })
      .then(resp => {
          setSnackbar({children:'Creation de nouveau mode de paiement avec success', severity: 'success'})
          setIsLoading(false)
          setStatus(true)
        //navigate('/login')
      })
      .catch(error => {
          setIsLoading(false)
          setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
          setStatus(true)
          //console.log(error.response.data.message)
          })*/
      }
  

    return (
      <Box mt={3}>
        {/* Category National */}
        { wallet.categoryId === 2 &&
          <FormProvider {...methods}>
          <form>
            <Stack spacing={1}>
              <Box sx={{
                display:'flex',
                
                //justifyContent:'space-between'
              }}>
                <img src={backendUrl+'image/logo/'+wallet.icon} alt={wallet.name} width={40} height={40}/>
                <Box sx={{ml:2}}>
                  <Typography sx={{
                    fontWeight:'bold'
                  }}>
                    {wallet.symbol}
                  </Typography>
                  <Typography sx={{color:'grey', fontStyle:'italic'}}>
                  {wallet.name}
                  </Typography>
                </Box>
              </Box>
              <Typography variant='h6' sx={{fontWeight:'bold'}}>aaa</Typography>

              <FormSelectImage name='paymentMethod' label={'Quel est votre mode de payement?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>
              <FormInput name='solde' label={`Combien de ${wallet.symbol} souhaitez vous retirer?`} error={!!errors?.solde} helperText={errors?.solde?.message} fullWidth/>
              {selectedCategory !== undefined && <FormInput name='compteNumber' label={`Veuillez entrer ${notationFormat} du destinataire ?`}  error={!!errors?.compteNumber} helperText={errors?.compteNumber?.message} fullWidth/>}
            {/***************************************************** */}

            <Button variant='contained' onClick={handleSubmit(onSubmit)}>Continuer</Button>

            </Stack>
          </form>
        </FormProvider>
        }
        {/* END Category National */}

        {/* END Category Fiat (USD) */}
        {
          wallet.categoryId === 1 &&

          <FormProvider {...methods}>
            <form>
              <Stack spacing={1}>
                <Box sx={{
                  display:'flex',
                  
                  //justifyContent:'space-between'
                }}>
                  <img src={backendUrl+'image/logo/'+wallet.icon} alt={wallet.name} width={40} height={40}/>
                  <Box sx={{ml:2}}>
                    <Typography sx={{
                      fontWeight:'bold'
                    }}>
                      {wallet.symbol}
                    </Typography>
                    <Typography sx={{color:'grey', fontStyle:'italic'}}>
                    {wallet.name}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant='h6' sx={{fontWeight:'bold'}}>aaa</Typography>

                <FormSelectImage name='paymentMethod' label={'Quel est votre mode de payement?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>
                <FormInput name='solde' label={`Combien de ${wallet.symbol} souhaitez vous retirer?`} error={!!errors?.solde} helperText={errors?.solde?.message} fullWidth/>
                {selectedCategory !== undefined && <FormInput name='compteNumber' label={`Veuillez entrer ${notationFormat} du destinataire ?`}  error={!!errors?.compteNumber} helperText={errors?.compteNumber?.message} fullWidth/>}
              {/***************************************************** */}

              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Continuer</Button>

              </Stack>
            </form>
          </FormProvider>
        }

        {/* END Fiat */}

        {/* crypto */}
        {
          wallet.categoryId === 3 &&
          <FormProvider {...methods}>
            <form>
              <Stack spacing={1}>
                <Box sx={{
                  display:'flex',
                  
                  //justifyContent:'space-between'
                }}>
                  <img src={backendUrl+'image/logo/'+wallet.icon} alt={wallet.name} width={40} height={40}/>
                  <Box sx={{ml:2}}>
                    <Typography sx={{
                      fontWeight:'bold'
                    }}>
                      {wallet.symbol}
                    </Typography>
                    <Typography sx={{color:'grey', fontStyle:'italic'}}>
                    {wallet.name}
                    </Typography>
                  </Box>
                </Box>
                <Typography variant='h6' sx={{fontWeight:'bold'}}>aaa</Typography>

                <FormSelectImage name='paymentMethod' label={'Quel est votre mode de payement?'} options={filteredPm} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>
                <FormInput name='solde' label={`Combien de ${wallet.symbol} souhaitez vous retirer?`} error={!!errors?.solde} helperText={errors?.solde?.message} fullWidth/>
                {selectedCategory !== undefined && <FormInput name='compteNumber' label={`Veuillez entrer ${notationFormat} du destinataire ?`}  error={!!errors?.compteNumber} helperText={errors?.compteNumber?.message} fullWidth/>}
              {/***************************************************** */}

              <Button variant='contained' onClick={handleSubmit(onSubmit)}>Continuer</Button>

              </Stack>
            </form>
          </FormProvider>
        }
        {/* END Crypto */}
      </Box>
    )
  }
