import React from 'react'
import ContentLoader from 'react-content-loader'

export const TwoBar = () => {
  return (
    <ContentLoader
        speed={2}
        width={400}
        height={150}
        viewBox="0 0 400 150"
        backgroundColor="#f3f3f311"
        foregroundColor="#ecebeb22"
              
            >
        <circle cx="10" cy="20" r="8" /> 
        <rect x="25" y="15" rx="5" ry="5" width="220" height="10" /> 
        <circle cx="10" cy="50" r="8" /> 
        <rect x="25" y="45" rx="5" ry="5" width="220" height="10" /> 
    </ContentLoader>
  )
}
