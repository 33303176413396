import { useQueries } from '@tanstack/react-query';
import React from 'react'
import { axiosClient, backendUrl } from '../utils';
import { TwoBarContentLoader } from '../component/contentLoading';
import { useParams } from 'react-router-dom';
import { Box, Button, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';

export const DepotTransaction = () => {
    const queries = [
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];
    
    const results = useQueries({ queries })
    
    const [wallets,categories,paymentMethod] = results
    
    
    return(
      <>
      {(
         categories.isLoading 
        || categories.data.data === undefined 
        || wallets.isLoading
        || wallets.data.data === undefined
        || paymentMethod.isLoading
        || paymentMethod.data.data === undefined
        ) && <TwoBarContentLoader/>}
    
      {categories.isSuccess 
        && categories.data.data !== undefined  
        && wallets.isSuccess
        && wallets.data.data !== undefined
        && paymentMethod.isSuccess
        && paymentMethod.data.data !== undefined
        && 
      <DepotTransactionLayout 
        wallets={wallets.data.data} 
        categories={categories.data.data}
        paymentMethodData={paymentMethod.data.data}
        />
      }
      </>
  )
}

const DepotTransactionLayout = ({categories, wallets, paymentMethodData}) => {
        
    const { id } = useParams()
    //const [wallet,setWallet] = useState(wallets.find(w => w.id === id))
    const wallet = wallets.find(w => w.id == id)
    //const category = categories.find(c => c.id == wallet.categoryId)
    const filteredPm = paymentMethodData.filter(payment => wallet.paymentMethod.includes(payment.id));

    //console.log('pm',filteredPm)
    //console.log('wal',wallet)
    //console.log('cat',category)

    return (
      <Box mt={3}>
        {/* Category National */}
        { wallet.categoryId === 2 &&
          <Stack spacing={1}>
            <Box sx={{
              display:'flex',
              
              //justifyContent:'space-between'
            }}>
              <img src={backendUrl+'image/logo/'+wallet.icon} width={40} height={40}/>
              <Box sx={{ml:2}}>
                <Typography sx={{
                  fontWeight:'bold'
                }}>
                  {wallet.symbol}
                </Typography>
                <Typography sx={{color:'grey', fontStyle:'italic'}}>
                {wallet.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quel est votre mode de payement?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={modePayement}
                label="Quel est votre mode de payement?"
                //onChange={(e=>setModePayement(e.target.value))}
              >
                {filteredPm.map((item,index)=> <MenuItem key={index}>
                  <Box display={'flex'}>
                    <Box
                      component={'img'}
                      src={backendUrl+'image/logo/'+item.icon}
                      width={30}
                      height={30}
                    />
                    <Typography sx={{ml:2}}>{item.name}</Typography>
                  </Box>
                </MenuItem>)}
              </Select>
            </FormControl>

            {/**************************************************** */}
            <TextField label="Combien d'Ariary souhaitez vous deposer?" fullWidth/>

            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quel est votre mode de payement?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={modePayement}
                label="Quel est votre mode de payement?"
                //onChange={(e=>setModePayement(e.target.value))}
              >
                
                <MenuItem value={'034568925'}>
                 034568925
                </MenuItem>
              </Select>
            </FormControl>

          {/***************************************************** */}

          <Button variant='contained'>Continuer</Button>
          </Stack>
        }
        {/* END Category National */}

        {/* END Category Fiat (USD) */}
        {
          wallet.categoryId === 1 &&
          <Stack spacing={1}>
            <Box sx={{
              display:'flex',
              
              //justifyContent:'space-between'
            }}>
              <img src={backendUrl+'image/logo/'+wallet.icon} width={40} height={40}/>
              <Box sx={{ml:2}}>
                <Typography sx={{
                  fontWeight:'bold'
                }}>
                  {wallet.symbol}
                </Typography>
                <Typography sx={{color:'grey', fontStyle:'italic'}}>
                {wallet.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quel est votre mode de payement?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={paymentmethodPayeer}
                label="Quel est votre mode de payement?"
               // onChange={(e=>setPaymentMethodPayeer(e.target.value))}
              >
                {filteredPm.map((item,index)=>{
                    return (<MenuItem key={index}>
                  <Box display={'flex'}>
                    <img src={backendUrl+'image/logo/'+item.icon} width={30} height={30}/>
                    <Typography sx={{ml:2}}>{item.name}</Typography>
                  </Box>
                </MenuItem>)})}
              </Select>
            </FormControl>

            {/**************************************************** */}
            <TextField label="Combien d'USD souhaitez vous deposer?" fullWidth/>

            
          {/***************************************************** */}

          <Button variant='contained'>Continuer</Button>
          </Stack>
        }

        {/* END Fiat */}

        {/* crypto */}
        {
          wallet.categoryId === 3 &&
          <Stack spacing={1}>
            <Box sx={{
              display:'flex',
              
              //justifyContent:'space-between'
            }}>
              <img src={backendUrl+'image/logo/'+wallet.icon} width={30} height={30}/>
              <Box sx={{ml:2}}>
                <Typography sx={{
                  fontWeight:'bold'
                }}>
                  {wallet.symbol}
                </Typography>
                <Typography sx={{color:'grey', fontStyle:'italic'}}>
                {wallet.name}
                </Typography>
              </Box>
            </Box>
            <Typography variant='h6' sx={{fontWeight:'bold'}}></Typography>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Quel est votre choix du Reseau?</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                //value={paymentmethodPayeer}
                label="Quel est votre choix du Reseau?"
                //onChange={(e=>setPaymentMethodPayeer(e.target.value))}
              >
                {filteredPm.map((item,index)=><MenuItem key={index}>
                  <Box display={'flex'}>
                    <img src={backendUrl+'image/logo/'+item.icon} width={30} height={30}/>
                    <Typography sx={{ml:2}}>{item.name}</Typography>
                  </Box>
                </MenuItem>)
                }
              </Select>
            </FormControl>

            {/**************************************************** */}
            <TextField label={"Combien de "+wallet.symbol+" souhaitez vous deposer?"} fullWidth/>

            
          {/***************************************************** */}

          <Button variant='contained'>Continuer</Button>
          </Stack>
        }
        {/* END Crypto */}
      </Box>
    )
  }
