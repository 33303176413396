import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { axiosClient, backendUrl } from '../utils'
import { useQueries } from '@tanstack/react-query'
import { Avatar, Box, Button, Chip, CircularProgress, Container, Divider, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Paper, Radio, RadioGroup, Select, Stack, TextField, Typography } from '@mui/material'
import { TwoBarContentLoader } from '../component/contentLoading'
import { RegexGeneration, Title } from '../component/admin'
import { MdEdit } from "react-icons/md"
import axios from 'axios'
import CancelIcon from "@mui/icons-material/Cancel"
import { SnackBarStatus } from '../component'

export const EditPaymentMethod = () => {

    const queries = [
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];

    const results = useQueries({ queries })

    const [categories, paymentMethods] = results


  return (
    <>
    {(
        paymentMethods.isLoading 
        || categories.isLoading 
        || categories.data.data === undefined 
        || paymentMethods.data.data === undefined) 
        && <TwoBarContentLoader/>
    }
    { 
        categories.isSuccess 
        && categories.data.data !== undefined  
        && paymentMethods.isSuccess 
        && paymentMethods.data.data !== undefined 
        && <EditPaymentMethodLayout paymentMethods={paymentMethods.data.data} paymentMethodsObject={paymentMethods} categories={categories.data.data}/>
    }
    </>
  )
}

const EditPaymentMethodLayout = ({paymentMethods,categories,paymentMethodsObject}) => {
    const { id } = useParams()

    const paymentMethod = paymentMethods.find(w => w.id == id)

    const filteredCategory = categories.filter(category => paymentMethod.categories.includes(category.id));
    //console.log(filteredCategory)
      console.log(paymentMethod.format)
    //serie ana useSatet edit
  const [isNameEdited,setNameEdited] = useState(false)
  const [isNameEditedLoading,setNameEditedLoading] = useState(false)
  const [nameEditedValue,setNameEditedValue] = useState(paymentMethod.name)

  const [isIconEdited,setIconEdited] = useState(false)
  const [isIconEditedLoading,setIconEditedLoading] = useState(false)
  const [iconEditedValue,setIconEditedValue] = useState('')


  const [isNotationFormatEdited,setNotationFormatEdited] = useState(false)
  const [isNotationFormatEditedLoading,setNotationFormatEditedLoading] = useState(false)
  const [notationFormatEditedValue,setNotationFormatEditedValue] = useState('')

  const [isFormatEdited,setFormatEdited] = useState(false)
  const [isFormatEditedLoading,setFormatEditedLoading] = useState(false)
  const [formatEditedValue,setFormatEditedValue] = useState('')
  const [formatMeaningEditedValue,setFormatMeaningEditedValue] = useState('')


  const [isCategoryEdited,setCategoryEdited] = useState(false)
  const [isCategoryEditedLoading,setCategoryEditedLoading] = useState(false)
  const [categoryEditedValue,setCategoryEditedValue] = useState(paymentMethod.categories)

  //snacbar

  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  const sendChange = (identifier,value,setEdited,setEditLoading) => {
    const dataForm = new FormData()
    dataForm.append('identifier',identifier)

    if(identifier==='category'){
      value.forEach((element,index) => {
        dataForm.append(`value[${index}]`,element)
      });
    }
    else {
      dataForm.append('value',value)
    }

    axios.post(backendUrl+'paymentMethod/'+id,dataForm,
      {
        headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:`Modification du ${identifier} avec success`, severity: 'success'})
      setEdited(false)
      setEditLoading(false)
      setStatus(true)
      paymentMethodsObject.refetch()
    })
    .catch(e=>{
      setSnackbar({children:`Erreur sur la modification du ${identifier}`, severity: 'error'})
      setEditLoading(false)
      setStatus(true)
      console.log(e)
    })
  }

  const sendChangeTwoValue = (identifier,value,valueTwo,setEdited,setEditLoading) => {
    let format
    let formatMeaning

    if(isNewFormat){
      format = value
      formatMeaning = valueTwo
    } else {
      format = paymentMethod.format+'|'+value
      formatMeaning =  paymentMethod.formatMeaning+' OU '+valueTwo
    }

    const dataForm = new FormData()
    dataForm.append('identifier',identifier)
    dataForm.append('value',format)
    dataForm.append('valueTwo',formatMeaning)
    

    axios.post(backendUrl+'paymentMethod/'+id,dataForm,
      {
        headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:`Modification du ${identifier} avec success`, severity: 'success'})
      setEdited(false)
      setEditLoading(false)
      setStatus(true)
      setPredefini('choose')
      paymentMethodsObject.refetch()
    })
    .catch(e=>{
      setSnackbar({children:`Erreur sur la modification du ${identifier}`, severity: 'error'})
      setEditLoading(false)
      setStatus(true)
      setPredefini('choose')
      console.log(e)
      alert(e)
    })
  }

  const handleChangeWallet = (identifier) => {
    console.log(identifier)
    if (identifier === 'name'){
      setNameEditedLoading(true)
      sendChange(identifier,nameEditedValue,setNameEdited,setNameEditedLoading)
    }

    if (identifier === 'icon'){
      setIconEditedLoading(true)
      sendChange(identifier,iconEditedValue,setIconEdited,setIconEditedLoading)
    }

    if (identifier === 'notationFormat'){
      setNotationFormatEditedLoading(true)
      sendChange(identifier,notationFormatEditedValue,setNotationFormatEdited,setNotationFormatEditedLoading)
    }

    if (identifier === 'format'){
      setFormatEditedLoading(true)
      sendChangeTwoValue(identifier,formatEditedValue,formatMeaningEditedValue,setFormatEdited,setFormatEditedLoading)
    }

    if (identifier === 'category'){
      setCategoryEditedLoading(true)
      sendChange(identifier,categoryEditedValue,setCategoryEdited,setCategoryEditedLoading)
    }

  }


  //image
  const [imagePreview, setImagePreview] = useState(null)

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
        setIconEditedValue(file)
      };
      reader.readAsDataURL(file);
    }
  }

  //for format only 
  const [isPredefini,setPredefini] = useState('choose')
  const [isNewFormat,setNewFormat] = useState(false)

 // const [objectFormat,setObjectFormat] = useState({})
  const handleChangeRadioFormat = (e) => {
    const val = JSON.parse(e.target.value)
    setFormatEditedValue(val.format)
    setFormatMeaningEditedValue(val.formatMeaning)
  }
  //end format only
  return (
    <>
    <Title title={"L'ACTIF "+paymentMethod.symbol}/>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          p:{xs:1,md:3}
        }}>
          <Stack spacing={1} sx={{px:2}}>
            {/* Name */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isNameEdited ?'white':'grey'}}>Nom</Typography>
                {!isNameEdited && <Typography>{paymentMethod.name}</Typography>}
              </Box>
                {!isNameEdited && <IconButton onClick={()=>setNameEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isNameEdited &&
            <>
            <TextField value={nameEditedValue} onChange={(e)=> setNameEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNameEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('name')} variant='contained' sx={{width:100}}>{isNameEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Name */}


            {/* icon */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isIconEdited ?'white':'grey'}}>Logo</Typography>
                {!isIconEdited && <img src={backendUrl+'image/logo/'+paymentMethod.icon} alt={paymentMethod.name+' icon'} width={40} height={40}/>}
              </Box>
                {!isIconEdited && <IconButton onClick={()=>setIconEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isIconEdited &&
            <>
            <Box sx={{
              display:'flex',
              alignItems:'center',
              mr:2
            }}>
              <Box>
              <input
                accept="image/*"
                type="file"
                onChange={(e) => handleImageChange(e)}
                style={{ display: 'none' }}
                id="raised-button-file"
              />
              <label htmlFor="raised-button-file">
                <Button variant="contained" component="span">
                  Upload Logo
                </Button>
              </label>
              </Box>
              {imagePreview && (
                <Box ml={2}>
                  <Avatar
                    alt="Image Preview"
                    src={imagePreview}
                    sx={{ width: 30, height: 30 }}
                  />
                </Box>
              )}
            </Box>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setIconEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('icon')} variant='contained' sx={{width:100}}>{isIconEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Icon */}


            {/* NotationFormat */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isNotationFormatEdited ?'white':'grey'}}>Format Notation</Typography>
                {!isNotationFormatEdited && <Typography>{paymentMethod.notationFormat}</Typography>}
              </Box>
                {!isNotationFormatEdited && <IconButton onClick={()=>setNotationFormatEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isNotationFormatEdited &&
            <>
            <TextField value={notationFormatEditedValue} onChange={(e)=> setNotationFormatEditedValue(e.target.value)}fullWidth sx={{my:1}}/>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setNotationFormatEdited(false)} sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('notationFormat')} variant='contained' sx={{width:100}}>{isNotationFormatEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END NotationFormat */}

            {/* Format */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isFormatEdited ?'white':'grey'}}>Format</Typography>
                {!isFormatEdited && <Typography>{paymentMethod.formatMeaning}</Typography>}
              </Box>
                {!isFormatEdited && <IconButton onClick={()=>setFormatEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isFormatEdited &&
            <>
            { isPredefini === 'choose' && <Stack spacing={1} sx={{my:1}}>
              <Button variant='outlined' onClick={()=>{
                setNewFormat(false)
                setPredefini('predefini')
              }}>Ajouter plus de format</Button>
              <Button variant='outlined' onClick={()=>{
                setNewFormat(true)
                setPredefini('predefini')
              }}>Nouveau format</Button>
            </Stack>
            }
            {isPredefini === 'predefini' &&
              <Box>
              <Paper sx={{py:2,px:1,mt:2, bgcolor:'#00000088', boxShadow:'none' }}>
                <Typography>Format Predefini</Typography>
                <FormControl >
                  <RadioGroup onChange={handleChangeRadioFormat}>
                  {/*<FormControlLabel value={JSON.stringify({format:"^((034|038)([0-9]{7}))|((039|032)([0-9]{7}))|((033)([0-9]{7})|([A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}))$", formatMeaning:'Test'})} control={<Radio sx={{m:0}}/>} label='Email' />*/}
                    <FormControlLabel value={JSON.stringify({format:"(^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$)", formatMeaning:'Email'})} control={<Radio sx={{m:0}}/>} label='Email' />
                    <FormControlLabel value={JSON.stringify({format:'(^(034|038)[0-9]{7}$)', formatMeaning:'Numero Telma'})} control={<Radio/>} label='Numero Telma'/>
                    <FormControlLabel value={JSON.stringify({format:'(^(039|032)[0-9]{7}$)', formatMeaning:'Numero Orange'})} control={<Radio/>} label='Numero Orange'/>
                    <FormControlLabel value={JSON.stringify({format:'(^(033)[0-9]{7}$)', formatMeaning:'Numero Airtel'})} control={<Radio/>} label='Numero Airtel'/>
                    <FormControlLabel value={JSON.stringify({format:'(^[a-zA-Z]+$)', formatMeaning:'Tous caractere'})} control={<Radio/>} label='Tous caractere'/>
                    <FormControlLabel value={JSON.stringify({format:'(^[0-9]+$)', formatMeaning:'Tous chiffre'})} control={<Radio/>} label='Tous caractere'/>
                  </RadioGroup>
                </FormControl>
                <Button variant='outlined' onClick={()=>setPredefini('nonpredefini')} sx={{width:'100%'}}>Format personalise</Button>
                </Paper>
                <Box sx={{
                    display:'flex',
                    justifyContent:'end',
                    mt:2
                  }}>
                      <Button onClick={()=>{setFormatEdited(false);setPredefini('choose')}} sx={{mr:1}}>Annuler</Button>
                      <Button onClick={()=>handleChangeWallet('format')} variant='contained' sx={{width:100}}>{isFormatEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
                </Box>
              </Box>
              }
            {  isPredefini === 'nonpredefini' && 
              <RegexGeneration regex={formatEditedValue} setRegex={setFormatEditedValue} finalMessageRegex={formatMeaningEditedValue} setFinalMessageRegex={setFormatMeaningEditedValue}>
              <Box sx={{
                  display:'flex',
                  justifyContent:'end'
                }}>
                    <Button onClick={()=>{setFormatEdited(false);setPredefini('choose')}} sx={{mr:1}}>Annuler</Button>
                    <Button onClick={()=>handleChangeWallet('format')} variant='contained' sx={{width:100}}>{isFormatEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
              </Box>
            </RegexGeneration>
            }
            </>
            }
            <Divider sx={{mt:1}}/>
            </Box>
            {/* END Format */}


            {/* Categories */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isCategoryEdited ?'white':'grey'}}>Category</Typography>
                {!isCategoryEdited && 
                  <Box sx={{display:'flex', flexWrap:'wrap'}}>
                    {filteredCategory.map((item,index)=>
                    <Chip key={index} label={item.name} sx={{m:0.5}} />
                    )}
                  </Box>
                }
              </Box>
                {!isCategoryEdited && <IconButton onClick={()=>setCategoryEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isCategoryEdited &&
            <>
            <Select 
              value={categoryEditedValue} 
              multiple 
              onChange={(e)=> setCategoryEditedValue(e.target.value)}fullWidth sx={{my:1}}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value, index) => {
                    const tempValue = categories.find(item => item.id === value);
                    return (
                      <Chip
                        key={index}
                        label={tempValue.name}
                        onDelete={() => {
                          setCategoryEditedValue(selected.filter((item) => item !== value))
                        }}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              )}>
            {categories.map((item,index)=>
              <MenuItem key={index} value={item.id}>
                <Box sx={{display:'flex'}}>
                  <Typography ml={1}>{item.name}</Typography>
                </Box>
              </MenuItem>)
            }
            </Select>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setCategoryEdited(false)}  sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('category')} variant='contained' sx={{width:100}}>{isCategoryEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            </Box>
            {/* END Category */}
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
          </Stack>
        </Paper>
      </Grid>
    </Grid>
    </>
  )
}
