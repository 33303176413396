import React from 'react'
import BackgroundImg from '../asset/image/background.jpeg'
import BackgroundMobileImg from '../asset/image/backgroundMobile.jpeg'
import { Box } from '@mui/material'
import { Login } from '../auth'

export const LoginPage = () => {
    return (
        <Box>
            {/* BackgroundImage */}
        <Box
            component={'img'}
            src={BackgroundImg}
            width={'100%'}
            height={'100vh'}
            sx={{
            display:{xs:'none',md:'flex'},
            position:'absolute',
            zIndex: -10,
            }}
        />

        <Box
            component={'img'}
            src={BackgroundMobileImg}
            width={'100%'}
            sx={{
            display:{xs:'flex',md:'none'},
            position:'absolute',
            zIndex: -10,
            }}
        />

        {/* END BackgroundImage */}
        
        <Login/>
        </Box>
    )
}
