import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import React from 'react'
import { MdEdit } from "react-icons/md";
import { backendUrl } from '../../utils'
import { useNavigate } from 'react-router-dom';

export const TableActif = ({id, img, name, symbol, category, paymentMethod }) => {
  const navigate = useNavigate()
  //console.log()
  return (
    <Paper sx={{
      display: {xs:'none',sm:'flex'},
      flexDirection: 'row',
      width: '100%',
      padding: 1,
      my:1,
      '&:hover': {
        backgroundColor:'#ffffff11',
      },
  
      //borderBottom: '1px solid #ccc',
      alignItems: 'center'
    }}>
      <Box sx={{ width:'10%'}}>
        <img src={backendUrl+'image/logo/'+img} alt={`${name} icon`} width={30} height={30} />
      </Box>
  
        <Typography sx= {{ width:'10%', }}>{name}</Typography> 
        <Typography sx= {{ width:'10%', }}>{symbol}</Typography>

  
      <Typography sx= {{ width:'15%', }}>{category}</Typography>
      <Box display={'flex'} alignItems={'center'} sx= {{ width:'45%'}}>
          {paymentMethod.slice(0, 5).map((item,index)=><Box key={index} sx={{mx:0.5}}><img src={backendUrl+'image/logo/'+item.icon} alt={`${item.name} icon`} width={30} height={30} /></Box>)}
          {paymentMethod.length>5 && <Typography sx={{color:'grey'}}>...</Typography>}
        </Box>
      <Box sx= {{ width:'10%', display:'flex',justifyContent:'center' }}>
        <IconButton onClick={()=>navigate('edit/'+id)}>
          <MdEdit />
        </IconButton>
      </Box>
    </Paper>
  )
}
export const TableActifHeader = () => (
    <Box sx={{
      display: {xs:'none',sm:'flex'},
      flexDirection: 'row',
      width: '100%',
      padding: 1,
      //backgroundColor: '#f0f0f0'
    }}>
      <Typography sx= {{ width:'30%'}}>Nom</Typography>
      <Typography sx={{width:'15%'}}>Category </Typography>
      <Typography sx= {{ width:'45%', }}>Mode de paiment</Typography>
      <Box sx= {{ width:'10%', }}></Box>
    </Box>
  )

  export const TableActifMobile = ({ id, img, symbol, category, paymentMethod }) => {
    //console.log()
    return (
      <Paper sx={{
        display: {xs:'flex',sm:'none'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'center'
      }}>
        <Box sx={{ width:'15%'}}>
          <img src={backendUrl+'image/logo/'+img} alt={`${symbol} icon`} width={30} height={30} />
        </Box>
    
        <Box sx={{
          display:'flex',
          width:'15%',  
        }}>
          <Typography sx={{ml:0}}>{symbol}</Typography>
        </Box>
        <Box sx={{
          display:'flex',
          width:'25%',  
        }}>
          <Typography sx={{ml:0}}>{category}</Typography>
        </Box>

        <Box display={'flex'} alignItems={'center'} sx= {{ width:'45%'}}>
          {paymentMethod.slice(0, 3).map((item,index)=><Box key={index} sx={{mx:0.5}}><img src={backendUrl+'image/logo/'+item.icon} alt={`${item.name} icon`} width={30} height={30} /></Box>)}
          {paymentMethod.length>3 && <Typography sx={{color:'grey'}}>...</Typography>}
        </Box>

      </Paper>
    )
  }
  export const TableActifHeaderMobile = () => (
      <Box sx={{
        display: {xs:'flex',sm:'none'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        justifyContent:'center'
        //backgroundColor: '#f0f0f0'
      }}>
        <Typography sx= {{ width:'10%'}}>Nom</Typography>
        <Box sx= {{ width:'20%', display:'flex', justifyContent:'end'}}></Box>
        <Box sx= {{ width:'25%', display:'flex',}}>Category</Box>
        <Typography sx= {{ width:'45%', }}>Mode de Paiement</Typography>
      </Box>
    )