import { Box, Button, CircularProgress, Container, Grid, Paper, Stack, TextField } from '@mui/material'
import React, { useState } from 'react'
import { Title } from '../component/admin'
import { backendUrl } from '../utils'
import { SnackBarStatus } from '../component'
import axios from 'axios'

export const NewCategory = () => {

  const [isLoading, setIsLoading]= useState(false)
  const [status,setStatus] = useState(false)
  const [snackbar,setSnackbar] = useState(null)
  const [name, setName] = useState('')

  const handleClick = () => {
    setIsLoading(true)
    if(!name){
      setIsLoading(false)
      return null
    }

    const dataForm = new FormData()

    dataForm.append('name',name)

    axios.post(backendUrl+'category',dataForm,
      {
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:'Creation de nouveau category avec success', severity: 'success'})
      setStatus(true)
      setIsLoading(false)
      setName('')
    })
    .catch((e)=>{
      console.log(e)
      setSnackbar({children:'Erreur sur la creation de nouveau category', severity: 'error'})
      setStatus(true)
      setIsLoading(false)
    })
  }


  return (
    <Container>
    <Box>
      {/* ajout de categories */}
      <Title title='Ajout De Nouveau Category'/>
      <Grid container sx={{mt:3}}>
            <Grid item  xs={12} md={6}>
            <Paper elevation={1} sx={{
                    width:{xs:'95%', md:'80%'},
                    height:'fit-content',
                    p:{xs:2,sm:4,md:6},
                    boxShadow:6,
                    backgroundColor:'#1e1e1ebb'
            }}>
              <Stack spacing={1}>             
                <TextField label='nom du category' fullWidth value={name} onChange={(e)=>setName(e.target.value)}/>
                <Button variant='contained' onClick={handleClick}>
                { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "AJOUTER UN NOUVEAU CATEGORY"}
                </Button>
                <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
              </Stack>
            </Paper>
          </Grid>
          </Grid>
    </Box>
    </Container>
  )

}