import { Button, CircularProgress, Container, Grid, Paper, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Title } from '../component/admin'

import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import { axiosClient, backendUrl} from '../utils'
import { FormInput, FormSelect, ImageInput} from '../component/form'
import { MultipleSelect } from '../component/form/MultipleSelect'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'
import { SnackBarStatus } from '../component'

export const NewWallet = () => {
  const queries = [
    {
      queryKey: ['category'],
      queryFn: () => axiosClient.get('category'),
    },
    {
      queryKey: ['allPaymentMethodInCategory'],
      queryFn: () => axiosClient.get('paymentIntoCategory'),
    },
    // Add more queries as needed
  ];

const results = useQueries({ queries })

const [categories ,allPaymentMethodInCategory] = results


return(
  <>
  {( 
     categories.isLoading 
    || categories.data.data === undefined 
    || allPaymentMethodInCategory.isLoading
    || allPaymentMethodInCategory.data.data === undefined
    ) && <TwoBarContentLoader/>}

  {categories.isSuccess 
    && categories.data.data !== undefined  
    && allPaymentMethodInCategory.isSuccess 
    && allPaymentMethodInCategory.data.data !== undefined 
    && 
  <NewWalletLayout 
    allPaymentMethodInCategory={allPaymentMethodInCategory.data.data} 
    categories={categories.data.data}/>
  }
  </>
)
}


const NewWalletLayout = ({allPaymentMethodInCategory,categories}) => {

  const ENDPOINT = backendUrl+'wallet'

  const [isLoading, setIsLoading]= useState(false)
  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  const [categoriesActive, setCategoriesActive] = useState(null)
  const [paymentMethod ,setPaymentMethod] = useState([])


  const dataForm = new FormData()

  const validationSchema = Yup.object().shape({
      name : Yup.string()
        .required("Veillez ajouter un nom d'utilisateur"),
      symbol: Yup.string()
        .required("Veillez ajouter un nom d'utilisateur"),
      image: Yup.mixed()
        .required('An image is required')
        .test('fileSize', 'Image trop large <2mb', (value) => {
          return value && value[0] && value[0].size <= 2000000; // 2MB limit
        })
        .test('fileType', "Type d'image non supporter ", (value) => {
          return value && value[0] && ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'].includes(value[0].type);
        }),
      category: Yup.number().required("choisissez un category"),
      paymentMethod: Yup.array().min(1, 'Select at least one payment method').required('Payment method is required'),
  })

  const methods = useForm({
      resolver:yupResolver(validationSchema),
      defaultValues: {
          name: '',
          symbol: '',
          category:'',
          paymentMethod: [],
        },
    })

    
  const {handleSubmit,watch,formState: { errors }, setValue} = methods

  // Watch the category field for changes
  const selectedCategory = watch('category');

  useEffect(() => {
      // Update categoriesActive whenever selectedCategory changes
      if(categoriesActive !== selectedCategory){
        setValue('paymentMethod',[])
        setCategoriesActive(selectedCategory);
      }
      
      const tempValue = allPaymentMethodInCategory.find(item => item.categoryId === selectedCategory);

      if(tempValue !== undefined){
        setPaymentMethod(tempValue.paymentMethod)
      }
      //id: 2, name: 'xraval7', icon: 

    }, [selectedCategory,allPaymentMethodInCategory,categoriesActive,setValue]);

    const onSubmit = (data) => {
      setIsLoading(true)
      console.log(data)

    dataForm.append('symbol',data.symbol)
    dataForm.append('name',data.name)
    dataForm.append('category',data.category)
    data.paymentMethod.forEach((id,index)=>{
        dataForm.append(`paymentMethod[${index}]`,id)
      })
    dataForm.append('icon',data.image[0])

    axios.post(ENDPOINT,dataForm,
    {
      headers : {
            'content-type' : 'multipart/form-data',
        },
      transformRequest: dataForm=> dataForm,//to solve axios issue
    })
    .then(resp => {
        setSnackbar({children:'Creation de nouveau mode de paiement avec success', severity: 'success'})
        setIsLoading(false)
        setStatus(true)
      
  })
  .catch(error => {
        setIsLoading(false)
        setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
        setStatus(true)
  })
  }

  return (
    <Container >
      
      <Title title={"AJOUT DE NOUVEAU ACTIF"}/>
    
      <Grid container sx={{mt:3}}>
            <Grid item  xs={12} md={6}>
            <Paper elevation={1} sx={{
                    width:{xs:'95%', md:'80%'},
                    height:'fit-content',
                    p:{xs:2,sm:4,md:6},
                    boxShadow:6,
                    backgroundColor:'#1e1e1ebb'
            }}>
                <Stack spacing={1}>
                    
                    <FormProvider {...methods}>
                    <form>
                        <Stack spacing={1}>
                            <FormInput name='name' label='Nom'  error={!!errors?.name} helperText={errors?.name?.message} fullWidth/>
                            <FormInput name='symbol' label="Symbole" error={!!errors?.symbol} helperText={errors?.symbol?.message} fullWidth/>
                            <ImageInput name='image' error={!!errors.image} helperText={errors?.image?.message}/>
                            <FormSelect name='category' label={'Category'} options={categories} error={!!errors?.category} helpertext={errors?.category?.message} fullWidth/>
                            <MultipleSelect name='paymentMethod' label={'Mode de Paiement'} options={paymentMethod} error={!!errors?.paymentMethod} helpertext={errors?.paymentMethod?.message} fullWidth/>
                            <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                            { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Ajouter"}
                            </Button>
                        </Stack>
                    </form>

                    </FormProvider>
                </Stack>
            </Paper>
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
            </Grid>

        </Grid>
    </Container>
  )
}
//<MultipleSelectSimple name='paymentMethod' label="Category" error={!!errors?.paymentMethod} options={categories} helpertext={errors?.paymentMethod?.message} />