import { Box, Paper, Typography } from '@mui/material'
import React from 'react'

export const Dashboard = () => {
  return (
    <Box>
      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>

      <Paper>
        <Typography p={9}>
          dashboard
        </Typography>
      </Paper>
    </Box>
  )
}
