import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
//import Button from '@mui/material/Button';
import { useEffect, useState } from 'react';
import { Grid, Tab, Tabs, Typography } from '@mui/material';
import { FaStar } from "react-icons/fa6"
import { CiStar } from "react-icons/ci"
import { useNavigate } from 'react-router-dom';
import { useAxios } from '../../utils';

export const BottomDrawer = ({trade,tradeObj,setCrypto,state, setState}) => {


  const navigate = useNavigate();

  //console.log('trade',trade)
  const favoriteTrade = trade
  .map(asset => ({
    ...asset,
    compatibleWallet: asset.compatibleWallet.filter(wallet => wallet.favorite === true)
  }))
  .filter(asset => asset.compatibleWallet.length > 0);  // Only keep assets that have non-favorite wallets

  //console.log('non',nonFavoriteWallets);

  const [tabActive,setTabActive] = useState(1)

  const [currentSubTab, setCurrentSubTab] = useState([])
  const [currentTab,setCurrentTab] = useState([])

  const handleChangeTab = (e,newValue) =>{
    setTabActive(newValue)
  }

  useEffect(()=>{
    const compatibleW = trade.find(i => i.id === tabActive)
    if(compatibleW === undefined){
      setCurrentTab([])
      setCurrentSubTab([])
    } else {
      setCurrentTab(compatibleW)
      setCurrentSubTab(compatibleW.compatibleWallet)  
    } 
  },[tabActive])

  /*useEffect(()=>{
    if(tabActive === 'favorite'){

      
    }
  },[tabActive])*/

  useEffect(() => {

    const handlePopState = (event) => {      
      if (!state) {
        // Navigate back if active is false
        navigate(-1)
      } else {
        // Push the current path to prevent navigation if active is true
        event.preventDefault(); // Optional: Prevent default popstate behavior
        setState(false)
        //navigate(0);
      }

    };

    // Listen for the popstate event
    window.addEventListener('popstate', handlePopState);

    return () => {
      // Cleanup the event listener when the component unmounts
      window.removeEventListener('popstate', handlePopState);
    };
  }, [state,navigate]);


  const axiosInstance = useAxios()

  const setFavoriteTrade = (id) => {
    axiosInstance.post('/api/tradeFavorite/'+id)
    .then((resp)=>{tradeObj.refetch()})
    .catch((e)=>{})
  }

  const list = () => (
    <Box
      sx={{ width:'auto', height:{xs:'90vh',md:'80vh'}, p:1 }}
      role="presentation"
     // onClick={()=>setState(false)}
      onKeyDown={()=>setState(false)}
    >
      <Box 
      onClick={()=>setState(false)}
      sx={{
        display:'flex',
        width:'100%',
        justifyContent:'center',
        p:1
      }}>
        <Box sx={{borderRadius:30, width:{xs:130,md:200}, height:13, backgroundColor:'#2b2b2b'}}/>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab value={'favoris'} label="Favoris" />
          {trade.map((item,index)=><Tab key={index} value={item.id} label={item.symbol}/>)}
        </Tabs>
      </Box>
      <Grid container>
        <Grid item xs={12} md={6} sx={{mt:1}}>
           {/* Header */}
           <Box sx={{display:'flex'}}>
              <Box sx={{width:30}}>

              </Box>

              <Box sx={{
                display: {xs:'flex',},
                flexDirection: 'row',
                width: '100%',
                //padding: 1,
                justifyContent:'center'
                }}>
                    <Typography sx= {{ width:'35%', color:'grey'}}>Nom/Vol</Typography>
                    <Box sx= {{ width:'25%', }}></Box>
                    <Typography sx= {{ width:'40%', color:'grey'}}>Dernier Prix</Typography>
              </Box>

            </Box>
            {/* END Header */}

            {/* start  list */}
            {currentSubTab.map((item,index)=>{

              return (
              <Box key={index} 
                onClick={()=>{
                  setCrypto(item.tradeId)
                  setState(false)
                }}
                sx={{
                  display:'flex', 
                  py:0.5,
                  my:0.5,
                // backgroundColor: 'red',
                  borderRadius:1,
                  '&:hover': {
                    backgroundColor:'#ffffff11',
                    cursor:'pointer',
                  }
                }}>
                  <Box 
                  onClick={()=>setFavoriteTrade(item.tradeId)}
                  sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                    {item.favorite ? <FaStar color='yellow' size={20}/> : <CiStar size={20}/>}
                  </Box>
                  <Box  
                    sx={{
                      display: {xs:'flex'}, //,sm:'none'
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      justifyContent:'center'
                  }}>
                      <Box sx={{
                        display:'flex',
                        alignItems:'baseline',
                        width:'35%',  
                        }}>
                      <Typography sx={{}}>{item.symbol}</Typography>
                      <Typography sx={{color:'grey', fontSize:10}}>/{currentTab.symbol}</Typography>
                      </Box>

                      <Box sx= {{ width:'25%'}}>

                      </Box>
                      
                      <Box sx= {{ width:'40%', display:'flex'}}>
                        5069
                      </Box>
                  </Box>
                </Box>)
              })}
            
            
          {/* END start  list  */}

          {/* tab favorite */}
          {tabActive === 'favoris' && favoriteTrade.map((i,index)=>{
              return (
                <Box key={index}>
                {
                i.compatibleWallet.map((item,index)=>{
                  console.log("item",i)
                  return (
                  <Box key={index} 
                    onClick={()=>{
                      setCrypto(item.tradeId)
                      setState(false)
                    }}
                    sx={{
                      display:'flex', 
                      py:0.5,
                      my:0.5,
                    // backgroundColor: 'red',
                      borderRadius:1,
                      '&:hover': {
                        backgroundColor:'#ffffff11',
                        cursor:'pointer',
                      }
                    }}>
                      <Box sx={{width:30, display:'flex', alignItems:'center', justifyContent:'center'}}>
                        <FaStar />
                      </Box>
                      <Box  
                        sx={{
                          display: {xs:'flex'}, //,sm:'none'
                          flexDirection: 'row',
                          width: '100%',
                          alignItems: 'center',
                          justifyContent:'center'
                      }}>
                          <Box sx={{
                            display:'flex',
                            alignItems:'baseline',
                            width:'35%',  
                            }}>
                          <Typography sx={{}}>{item.symbol}</Typography>
                          <Typography sx={{color:'grey', fontSize:10}}>/{i.symbol}</Typography>
                          </Box>

                          <Box sx= {{ width:'25%'}}>

                          </Box>
                          
                          <Box sx= {{ width:'40%', display:'flex'}}>
                            5069
                          </Box>
                      </Box>
                    </Box>)
                })
                }
                </Box>
            )}
          )}
              
            
          {/* END tab favorite */}


            {/* start  list */}
          {/*  <Box sx={{
              display:'flex', 
              py:1
            }}>
              <Box sx={{width:30, display:'flex', alignItems:'center'}}>
              <CiStar size={20}/>
              </Box>
              <Box  
                sx={{
                  display: {xs:'flex'}, //,sm:'none'
                  flexDirection: 'row',
                  width: '100%',
                  //padding: 1,
                  //my:1,    
                  alignItems: 'center',
                  justifyContent:'center'
              }}>
                  <Box sx={{
                    display:'flex',
                    width:'35%',  
                    }}>
                  <Typography sx={{}}>item.name</Typography>
                  </Box>

                  <Box sx= {{ width:'25%'}}>

                  </Box>
                  
                  <Box sx= {{ width:'40%', display:'flex'}}>
                    5069
                  </Box>
              </Box>
            </Box>*/}
            
        {/* END start  list  */}

        </Grid>
      </Grid>
     {/* <FaStar />
      <CiStar size={20}/> */}
    </Box>
  );

  return (
    <div>
          <SwipeableDrawer
            anchor={"bottom"}
            elevation={1}
            open={state}
            onClose={()=>setState(false)}
            onOpen={()=>setState(true)}
            sx={{borderRadius:30}}
          >
            {list()}
          </SwipeableDrawer>
    </div>
  );
}
