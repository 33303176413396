import React from "react";
import { OutlinedInput, InputLabel, MenuItem, Select, FormControl, Stack, Chip, Button, Typography, Box } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check"
import { Controller, useFormContext } from "react-hook-form";
import { backendUrl } from "../../utils";



export const MultipleSelect = (props) => {
  
  const {control} = useFormContext()
  const {name,label,options,error,helpertext} = props


  return (
        <Controller
          name={name}
          control={control}
          fullWidth={true}
          defaultValue={[]}
          render={({ field }) => (
            <FormControl >
            <InputLabel  id={name}>{label}</InputLabel>
            <Select
              {...field}
              multiple
              labelId={name} 
              label={label}
               {...field} {...props}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value, index) => {
                    const tempValue = options.find(item => item.id === value);
                    return (
                      <Chip
                        key={index}
                        label={tempValue.name}
                        avatar={<img src={`${backendUrl}image/logo/${tempValue.icon}`} alt={`${tempValue.name} icon`} width={40} height={40} />}
                        onDelete={() => {
                          const filtered = selected.filter((item) => item !== value);
                          field.onChange(filtered);
                        }}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              )}
            >
              {options.map((mo,index) => (
                <MenuItem
                key={index}
                value={mo.id}
                sx={{ justifyContent: "space-between" }}
              >
                <Box sx={{
                    display:'flex',
                    alignItems:'center'
                }}>
                    <img src={`${backendUrl}image/logo/${mo.icon}`} alt={`${mo.name} icon`} width={40} height={40} />
                    <Typography ml={1}>{mo.name}</Typography>
                
                </Box>
                  {field.value.includes(mo.id) ? <CheckIcon color="info" /> : null}
                </MenuItem>
              ))}
            </Select>

            {error && <Typography color="error" sx={{fontSize:13,ml:2}}>{helpertext}</Typography>}
          </FormControl>  
          )}
        />
  );
};
