import { Alert, Snackbar } from '@mui/material'
import React from 'react'

export const SnackBarStatus = ({open,setOpen,snackbar}) => {
  return (
    <Snackbar
      open={open} 
      onClose={()=>setOpen(false)} 
      anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
      autoHideDuration={3000}
    >
      <Alert {...snackbar} onClose={()=>setOpen(false)}/>
    </Snackbar>
  )
}

/**
 * 
 * 
 *  <Snackbar 
            open 
            onClose={handleCloseSnackbar} 
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            autoHideDuration={3000}
            >
            <Alert {...snackbar} onClose={handleCloseSnackbar}/>
            </Snackbar>
            {children:'Modification fait avec success', severity: 'success'}
            setSnackbar({children:'Erreur de Modification', severity: 'error'})
 */