import React from 'react'
import { Title } from '../component/admin'
import { Box, Button, Chip, Container, Grid, IconButton, Paper, Stack, Typography } from '@mui/material'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useQueries } from '@tanstack/react-query'
import { axiosClient, backendUrl } from '../utils'
import { TbCirclePlus } from 'react-icons/tb'
import { useNavigate } from 'react-router-dom'
import { MdEdit } from 'react-icons/md'

export const PaymentMethod = () => {

/*
    //data categories
    const {data:categories,isLoading:categoryLoading,isError:errorCategory, isSuccess:successCategory} = useQuery({queryKey:['category'],queryFn: ()=>
        axiosClient.get('category')
          .then((res) => res.data)
    })
*/
    const queries = [
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        {
          queryKey: ['paymentMethod'],
          queryFn: () => axiosClient.get('paymentMethod'),
        },
        // Add more queries as needed
      ];

    const results = useQueries({ queries })

    const [categories, paymentMethods] = results


    return (
        <>
        {(
            paymentMethods.isLoading 
            || categories.isLoading 
            || categories.data.data === undefined 
            || paymentMethods.data.data === undefined) 
            && <TwoBarContentLoader/>
        }
        { 
            categories.isSuccess 
            && categories.data.data !== undefined  
            && paymentMethods.isSuccess 
            && paymentMethods.data.data !== undefined 
            && <PaymentMethodLayout paymentMethods={paymentMethods.data.data} categories={categories.data.data}/>
        }
        </>
    )
   
}

const PaymentMethodLayout = ({paymentMethods,categories}) => {

  const navigate = useNavigate()

  return (
    <>
    <Container>
    <Grid container>
        <Grid item xs={12} md={6}>
            
            <Title title={'Methode de paiement'}/>

          <Button variant='outlined' startIcon={<TbCirclePlus />} onClick={()=>navigate('new')}>
            Nouveau
          </Button>
            {/* Header */}
            <Box sx={{
                    display: {xs:'flex',},//sm:'none'
                    flexDirection: 'row',
                    width: '100%',
                    padding: 1,
                    justifyContent:'center'
                    //backgroundColor: '#f0f0f0'
                    }}>
                        <Typography sx= {{ width:'25%'}}>Nom</Typography>
                        <Box sx= {{ width:'10%', display:'flex', justifyContent:'end'}}></Box>
                        <Typography sx= {{ width:'35%', }}>Category</Typography>
                        <Box sx= {{ width:'25%', }}></Box>
                    </Box>

                    {/* END Header */}
            
                

                <Stack spacing={1}>

                    {paymentMethods.map((item,index)=>
                    
                        <Paper key={index} sx={{
                            display: {xs:'flex'}, //,sm:'none'
                            flexDirection: 'row',
                            width: '100%',
                            padding: 1,
                            my:1,
                        
                            //borderBottom: '1px solid #ccc',
                            alignItems: 'center',
                            justifyContent:'center'
                        }}>
                            <Box sx={{ width:'15%'}}>
                            
                            <img src={`${backendUrl}image/logo/${item.icon}`} alt={`${item.name} icon`} width={30} height={30} />
                            </Box>
                        
                            <Box sx={{
                            display:'flex',
                            width:'25%',  
                            }}>
                            <Typography sx={{ml:2}}>{item.name}</Typography>
                            </Box>

                            <Box sx= {{ width:'45%'}}>
                                {
                                    item.categories.map((value,index)=>{    
                                    const tempValue = categories.find(item => item.id === value);
                                    return <Chip key={index} label={tempValue.name} sx={{m:0.5}} />
                                    })
                                }
                                
                            </Box>
                            <Box sx= {{ width:'15%', display:'flex',justifyContent:'center' }}>
                            <IconButton onClick={()=>navigate('edit/'+item.id)}>
                                <MdEdit />
                            </IconButton>
                            </Box>
                        </Paper>)
                    } {/* END paymentmwthod Map */}

                </Stack>

            </Grid>
        </Grid>
        </Container>
     </>
    
  )
}