import React, { useState } from 'react'
import { Title } from '../component/admin'
import { FormProvider, useForm} from 'react-hook-form'
import { yupResolver }from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios'
import {  Button,  CircularProgress, Container, Grid, Paper, Stack,} from '@mui/material'
import { FormInput, ImageInput, MultipleSelectSimple } from '../component/form'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useQueries, } from '@tanstack/react-query'
import { axiosClient, backendUrl } from '../utils'
import { SnackBarStatus } from '../component'

export const NewPaymentMethod = () => {

    const queries = [
        {
          queryKey: ['category'],
          queryFn: () => axiosClient.get('category'),
        },
        // Add more queries as needed
      ];

    const results = useQueries({ queries })

    const [categories] = results

    return (
        <>
        {(categories.isLoading || categories.data.data === undefined )&& <TwoBarContentLoader/>}
        {categories.isSuccess && categories.data.data !== undefined  && 
        <NewPaymentMethodLayout categories={categories.data.data}/>
        }
        </>
    )
   
}


const NewPaymentMethodLayout = ({categories}) => {

    const ENDPOINT = backendUrl+'paymentMethod'

    const [isLoading, setIsLoading]= useState(false)
    const [snackbar,setSnackbar] = useState(null)
    const [status,setStatus] = useState(false)


    const dataForm = new FormData()

  const validationSchema = Yup.object().shape({
    name : Yup.string()
        .required("Veillez ajouter un nom d'utilisateur"),
    icon: Yup.mixed()
        .required('An image is required')
        .test('fileSize', 'Image trop large <2mb', (value) => {
            return value && value[0] && value[0].size <= 2000000; // 2MB limit
            })
        .test('fileType', "Type d'image non supporter ", (value) => {
            return value && value[0] && ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'].includes(value[0].type);
            }),
    paymentMethod: Yup.array().min(1, 'Select at least one payment method').required('Payment method is required'),
  })

  const methods = useForm({
      resolver:yupResolver(validationSchema),
      defaultValues: {
          name: '',
          paymentMethod: [],
        },
    })

    
  const {handleSubmit,formState: { errors }} = methods

    const onSubmit = (data) => {
      setIsLoading(true)
      console.log(data.paymentMethod)

      data.paymentMethod.forEach((id,index)=>{
        dataForm.append(`paymentMethod[${index}]`,id)
        console.log(id)
      })
      
    dataForm.append('name',data.name)
    dataForm.append('icon',data.icon[0])
    
    axios.post(ENDPOINT,dataForm,
    {
        headers : {
            'content-type' : 'multipart/form-data',
        },
      transformRequest: dataForm => dataForm,//to solve axios issue
    })
    .then(resp => {
        setSnackbar({children:'Creation de nouveau mode de paiement avec success', severity: 'success'})
        setIsLoading(false)
        setStatus(true)
      //navigate('/login')
    })
    .catch(error => {
        setIsLoading(false)
        setSnackbar({children:'Erreur sur la creation de nouveau mode de paiement', severity: 'error'})
        setStatus(true)
        //console.log(error.response.data.message)
        })
    }

  return (
    <>
            <Container>
            <Title title={"Ajout de nouveau mode de paiement"}/>
            <Grid container sx={{mt:3}}>
                <Grid item  xs={12} md={6}>
                <Paper elevation={1} sx={{
                        //width:{xs:'95%', md:'80%'},
                        height:'fit-content',
                        p:{xs:2,sm:4,md:6},
                        boxShadow:6,
                        backgroundColor:'#1e1e1ebb'
                }}>
                    <Stack spacing={1}>
                        
                        <FormProvider {...methods}>
                        <form>
                            <Stack spacing={1}>
                                <FormInput name='name' label='Nom'  error={!!errors?.name} helperText={errors?.name?.message} fullWidth/>
                                <ImageInput name='icon' error={!!errors.icon} helperText={errors?.icon?.message}/>
                                <MultipleSelectSimple name='paymentMethod' label="Category" error={!!errors?.paymentMethod} options={categories} helpertext={errors?.paymentMethod?.message} />
                                <Button variant='contained' onClick={handleSubmit(onSubmit)}>
                                { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Ajouter"}
                                </Button>
                            </Stack>
                        </form>
                        </FormProvider>
                    </Stack>
                </Paper>
                </Grid>
    
            </Grid>
        </Container>
        
    
    {/* Snackbar add */}
    <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
    </>
    
  )
}