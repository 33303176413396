import { Box, Container, Tab, Tabs } from '@mui/material'
import React, { useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

export const Transaction = () => {
  const [tabActive, setTabActive] = useState(0)
  const navigate = useNavigate()

  const handleChangeTab = (e,v) => {
      setTabActive(v)
      if(tabActive === 1){
        navigate('/transaction')
      }
      else {
        navigate('/transaction/retrait')
      }
  }

  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab  label="Depot" />
          <Tab  label="Retrait"/>
        </Tabs>
      </Box>
      <Outlet/>
    </Container>
  )
}
