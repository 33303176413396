import React, { useEffect, useState } from 'react'
import { Navigate, Outlet} from 'react-router-dom'
import { Box, CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from '../theme'
import { useAuth } from '../auth'

export const AppOutlet = () => {

    const auth = useAuth()
    const [toHome,setToHome] = useState(false)
    const [stayLogin,setToStayLogin] = useState(false)

    useEffect(()=>{
        if(auth.isAuthenticated !== null && auth.isAuthenticated=== true){
          setToHome(true)
        }
        else if (auth.isAuthenticated !== null && auth.isAuthenticated=== false)
        {
            setToStayLogin(true)
        }
    },[auth.isAuthenticated])

    return (
        <ThemeProvider theme={theme}>
        <CssBaseline/>  
            <Box sx={{ width: '100%'}}>
                <>
                { stayLogin &&
                    <Outlet/>
                }
                { toHome &&
                <Navigate to='/'/> 
                }
                </>
            </Box>  
    </ThemeProvider>
    )
}