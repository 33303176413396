import { Box, Button, Container, Grid, } from '@mui/material'
import React from 'react'
import { Title } from '../component/admin'

import { axiosClient} from '../utils'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'
import { TableActif, TableActifHeader, TableActifHeaderMobile, TableActifMobile } from '../component/table/TableActif'
import { TbCirclePlus } from "react-icons/tb"
import { useNavigate } from 'react-router-dom'

export const WalletAdmin = () => {
  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    {
      queryKey: ['category'],
      queryFn: () => axiosClient.get('category'),
    },
    {
      queryKey: ['paymentMethod'],
      queryFn: () => axiosClient.get('paymentMethod'),
    },
    // Add more queries as needed
  ];

const results = useQueries({ queries })

const [wallets,categories,paymentMethod] = results


return(
  <>
  {(
     categories.isLoading 
    || categories.data.data === undefined 
    || wallets.isLoading
    || wallets.data.data === undefined
    || paymentMethod.isLoading
    || paymentMethod.data.data === undefined
    ) && <TwoBarContentLoader/>}

  {categories.isSuccess 
    && categories.data.data !== undefined  
    && wallets.isSuccess
    && wallets.data.data !== undefined
    && paymentMethod.isSuccess
    && paymentMethod.data.data !== undefined
    && 
  <WalletAdminLayout 
    wallets={wallets.data.data} 
    categories={categories.data.data}
    paymentMethodData={paymentMethod.data.data}
    />
  }
  </>
)
}

const WalletAdminLayout = ({categories, wallets, paymentMethodData}) => {

  const navigate = useNavigate()

  return (
    <Container >
      {/* Liste wallet*/}
      <Title title={'LES ACTIFs'}/>
      <Grid container mt={3}>
        <Grid item xs={12} md={7}>
          <Button variant='outlined' startIcon={<TbCirclePlus />} onClick={()=>navigate('new')}>
            Nouveau
          </Button>
      <TableActifHeader />
        <TableActifHeaderMobile />
        { //Object.keys(price).length > 0 &&
          wallets.map((item,index)=>{
            //const pm = paymentMethodData.filter()
            /*const filteredPm = paymentMethodData.filter(payment => 
              item.some(w => w.paymentMethod.includes(payment.id))
            )*/

            const filteredPm = paymentMethodData.filter(payment => item.paymentMethod.includes(payment.id));

            return (<Box key={index}>
            <TableActif  id={item.id} img={item.icon} name={item.name} symbol={item.symbol} category={categories.find(i => i.id === item.categoryId).name} paymentMethod={filteredPm} />
              <Box onClick={()=>navigate('edit/'+item.id)}>
                <TableActifMobile id={item.id} img={item.icon} symbol={item.symbol} category={categories.find(i => i.id === item.categoryId).name} paymentMethod={filteredPm} />
              </Box>
            </Box>)
          })
        }
        </Grid>
      </Grid>

      {/* END Liste wallet*/}
      
    </Container>
  )
}
//<MultipleSelectSimple name='paymentMethod' label="Category" error={!!errors?.paymentMethod} options={categories} helpertext={errors?.paymentMethod?.message} />