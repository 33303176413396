import { Box, Container } from '@mui/material'
import React from 'react'
import { CryptoCotation, CryptoCotationHeader, CryptoCotationHeaderMobile, CryptoCotationMobile } from '../component'
import { axiosClient } from '../utils'
import { useStateContext } from '../context'
import { TwoBarContentLoader } from '../component/contentLoading'
import { useQueries } from '@tanstack/react-query'

export const Contation = () => {
  

  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets] = results

  return (
    <>
    {( wallets.isLoading
      || wallets.data.data === undefined
      ) && <TwoBarContentLoader/>}

    { wallets.isSuccess
      && wallets.data.data !== undefined
      && 
      <ContationLayout wallets={wallets.data.data}/> 
    }
    </>
  )
}

const ContationLayout = ({wallets}) => {

  const {price} = useStateContext()
  const walletsWithouMGA = wallets.filter(item => item.categoryId !== 2)
  return (<>
  <Container sx={{
      display:'flex',
      flexDirection:'column',
      alignItems:'center',
      width:{xs:'100%',md:700}
  }}>
    
      <CryptoCotationHeader />
      <CryptoCotationHeaderMobile />

      { Object.keys(price).length > 0 &&
        walletsWithouMGA.map((item,index)=>{
          //const priceValue= price.find(i => )

          const priceValue = price.find(obj => obj.hasOwnProperty(item.symbol))[item.symbol];
          //console.log()
          return (
          <Box key={index} sx={{width:'100%'}}>
          <CryptoCotation  img={item.icon} name={item.name} symbol={item.symbol} price={price[index][item.symbol]} cours={item.cours} />
          <CryptoCotationMobile img={item.icon} symbol={item.symbol} price={priceValue} cours={item.cours} />
          </Box>)
        })
      }
  </Container>
  </>)
}
