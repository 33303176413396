import { Avatar, Box, Button, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const ImageInput = (props) => {

  const [imagePreview, setImagePreview] = useState(null)
  const {name,error,helperText} = props

  const {control} = useFormContext()

  const handleImageChange = (event, field) => {
    const file = event.target.files[0];
    field.onChange(event.target.files);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }


  return (
    <Controller
    name={name}
    control={control}
    defaultValue={null}
    render={({ field }) => (
      <Box sx={{
        display:'flex',
        alignItems:'center',
        mr:2
      }}>
        <Box>
        <input
          accept="image/*"
          type="file"
          onChange={(e) => handleImageChange(e, field)}
          style={{ display: 'none' }}
          id="raised-button-file"
        />
        <label htmlFor="raised-button-file">
          <Button variant="contained" component="span">
            Upload Logo
          </Button>
        </label>
        {error && <Typography color="error" sx={{fontSize:13,ml:2}}>{helperText}</Typography>}
        </Box>
        {imagePreview && (
          <Box ml={2}>
            <Avatar
              alt="Image Preview"
              src={imagePreview}
              sx={{ width: 30, height: 30 }}
            />
          </Box>
        )}
      </Box>
    )}
  />
  )
}
