import React, { useState } from 'react'
import { axiosClient, backendUrl } from '../utils';
import { useQueries } from '@tanstack/react-query';
import { TwoBarContentLoader } from '../component/contentLoading';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Box, Button, Chip, CircularProgress, Grid, IconButton, MenuItem, Paper, Select, Stack, Typography } from '@mui/material';
import { Title } from '../component/admin';
import { MdEdit } from 'react-icons/md';
import CancelIcon from "@mui/icons-material/Cancel"
import { SnackBarStatus } from '../component';


export const EditTrade = () => {
    const queries = [
        {
          queryKey: ['trade'],
          queryFn: () => axiosClient.get('trade'),
        },
        {
          queryKey: ['wallet'],
          queryFn: () => axiosClient.get('wallet'),
        },
        // Add more queries as needed
      ];
    
      const results = useQueries({ queries })
    
      const [trade,wallets] = results
    
      return (
        <>
        {( 
           trade.isLoading
          || trade.data.data === undefined
          || wallets.isLoading
          || wallets.data.data === undefined
          ) && <TwoBarContentLoader/>}
    
        {  trade.isSuccess
          && trade.data.data !== undefined
          && wallets.isSuccess
          && wallets.data.data !== undefined
          &&
          <EditTradeLayout 
            tradeObject={trade} 
            trade={trade.data.data}
            wallets={wallets.data.data} 
          /> 
        }</>
  )
}

const EditTradeLayout = ({trade, tradeObject, wallets}) => {
    const { id } = useParams()
    const trades = trade.find(w => w.id == id)
    console.log('mety')

    const idCompatibleWallet =trades.compatibleWallet.map(item => item.id);
    //console.log('id',idCompatibleWallet)

  //serie ana useSatet edit
  const [isPaymentMethodEdited,setPaymentMethodEdited] = useState(false)
  const [isPaymentMethodEditedLoading,setPaymentMethodEditedLoading] = useState(false)
  const [paymentMethodEditedValue,setPaymentMethodEditedValue] = useState(idCompatibleWallet)

  //snacbar

  const [snackbar,setSnackbar] = useState(null)
  const [status,setStatus] = useState(false)

  const sendChange = (value,setEdited,setEditLoading) => {
    const dataForm = new FormData()

      value.forEach((element,index) => {
        dataForm.append(`value[${index}]`,element)
      });


    axios.post(backendUrl+'trade/'+trades.id,dataForm,
      {
        headers : {
              'content-type' : 'multipart/form-data',
          },
        transformRequest: dataForm=> dataForm,//to solve axios issue
      })
    .then((resp)=>{
      setSnackbar({children:`Modification du trade avec success`, severity: 'success'})
      setEdited(false)
      setEditLoading(false)
      setStatus(true)
      tradeObject.refetch()
    })
    .catch(e=>{
      setSnackbar({children:`Erreur sur la modification du trade`, severity: 'error'})
      setEditLoading(false)
      setStatus(true)
      console.log(e)
    })
  }

  const handleChangeWallet = () => {

      setPaymentMethodEditedLoading(true)
      sendChange(paymentMethodEditedValue,setPaymentMethodEdited,setPaymentMethodEditedLoading)


  }

  return (
    <>
    <Title title={"L'ACTIF de base "+trades.symbol}/>
    <Grid container>
      <Grid item xs={12} md={6}>
        <Paper sx={{
          p:{xs:1,md:3}
        }}>
          <Stack spacing={1} sx={{px:2}}>
            {/* PaymentMethod */}
            <Box>
            <Box sx={{
              display:'flex',
              justifyContent:'space-between',
              alignItems:'end'
            }}>
              <Box>
                <Typography sx={{color: isPaymentMethodEdited ?'white':'grey'}}>Methode de Paiement</Typography>
                {!isPaymentMethodEdited && 
                  <Box sx={{display:'flex', flexWrap:'wrap'}}>
                    {trades.compatibleWallet.map((item,index)=>
                    <Box key={index} m={0.5}>
                      <img src={backendUrl+'image/logo/'+item.icon} alt={item.name} width={30} height={30} />
                    </Box>)
                    }
                  </Box>
                }
              </Box>
                {!isPaymentMethodEdited && <IconButton onClick={()=>setPaymentMethodEdited(true)}>
                  <MdEdit style={{fontSize:21,marginBottom:3}}/>
                </IconButton>}
            </Box>

            { isPaymentMethodEdited &&
            <>
            <Select 
              value={paymentMethodEditedValue} 
              multiple 
              onChange={(e)=> setPaymentMethodEditedValue(e.target.value)} fullWidth sx={{my:1}}
              renderValue={(selected) => (
                <Stack gap={1} direction="row" flexWrap="wrap">
                  {selected.map((value, index) => {
                    const tempValue = wallets.find(item => item.id == value);
                    console.log('temp',tempValue)
                    return (
                      <Chip
                        key={index}
                        label={tempValue.name}
                        avatar={<img src={`${backendUrl}image/logo/${tempValue.icon}`} alt={`${tempValue.name} icon`} width={30} height={30} />}
                        onDelete={() => {
                          setPaymentMethodEditedValue(selected.filter((item) => item !== value))
                        }}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    );
                  })}
                </Stack>
              )}>
            {wallets.map((item,index)=>
              <MenuItem key={index} value={item.id}>
                <Box sx={{display:'flex'}}>
                  <img src={`${backendUrl}image/logo/${item.icon}`} alt={`${item.name} icon`} width={30} height={30} />
                  <Typography ml={1}>{item.name}</Typography>
                </Box>
              </MenuItem>)}
            </Select>
            <Box sx={{
                display:'flex',
                justifyContent:'end'
              }}>
                  <Button onClick={()=>setPaymentMethodEdited(false)}  sx={{mr:1}}>Annuler</Button>
                  <Button onClick={()=>handleChangeWallet('paymentMethod')} variant='contained' sx={{width:100}}>{isPaymentMethodEditedLoading ? <CircularProgress sx={{color:'white'}} size={25}/>:'Enregistrer'}</Button>
            </Box>
            </>
            }
            </Box>
            {/* END PaymentMethod */}
            <SnackBarStatus snackbar={snackbar} open={status} setOpen={setStatus}/>
          </Stack>
        </Paper>
        </Grid>
    </Grid>
    </>
  )


}