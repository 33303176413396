import { Box, Button, Container, Stack, Tab, Tabs, Typography } from '@mui/material'
import React, { useState } from 'react'
import { WalletShow } from '../component'
import { axiosClient, money } from '../utils'
import { useNavigate } from 'react-router-dom'
import { useQueries } from '@tanstack/react-query'
import { TwoBarContentLoader } from '../component/contentLoading'

export const Wallet = () => {
  const [tabActive,setTabActive] = useState('one')

  const queries = [
    {
      queryKey: ['wallet'],
      queryFn: () => axiosClient.get('wallet'),
    },
    // Add more queries as needed
  ];

  const results = useQueries({ queries })

  const [wallets] = results

  const handleChangeTab = (e,newValue) =>{
    setTabActive(newValue)
  }
  return (
    <Container>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabActive} onChange={handleChangeTab} aria-label="basic tabs example">
          <Tab value="one" label="Mes Actifs" />
          <Tab value="two" label="Historique" />
        </Tabs>
      </Box>
      { tabActive === 'one' &&
      <>
        
        <>
      {( wallets.isLoading
        || wallets.data.data === undefined
        ) && <TwoBarContentLoader/>}

      { wallets.isSuccess
        && wallets.data.data !== undefined
        && 
        <WalletActif wallets={wallets.data.data}/> 
      }
      </>
      </>
      }
    </Container>
  )
}

const WalletActif = ({wallets}) => {

  const navigate = useNavigate()
  
  return (
    <>
    <Typography mt={3}>
        Total
    </Typography>
    <Typography sx={{
        fontSize:18,
        fontWeight:'bold',
      }}>
        2 359 505 Ar
    </Typography>

    <Box display={'flex'} mt={2}>
      <Button variant='outlined' sx={{mr:1}} onClick={()=>{navigate('/transaction')}}>
        Depot
      </Button>
      <Button variant='outlined' sx={{ml:1}} onClick={()=>{navigate('/transaction/retrait')}}>
        Retrait
      </Button>
    </Box>

    <Typography mt={3}>Les actifs dans mon portefeuille</Typography>
    <Stack spacing={1} mt={3}>
      {
        wallets.map((money,index)=>{
          return <WalletShow key={index} img={money.icon} name={money.name} symbol={money.symbol} value={0}/>
        })
      }
    </Stack>
    </>
  )
}