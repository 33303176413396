import { Box, Paper, Typography } from '@mui/material'
import React from 'react'
import { backendUrl } from '../utils'

export const WalletShow = ({img,symbol,name,value}) => {
  return (
    
    <Paper sx={{
        display: {xs:'flex',sm:'flex'},
        flexDirection: 'row',
        width: '100%',
        padding: 1,
        my:1,
        px:3,
    
        //borderBottom: '1px solid #ccc',
        alignItems: 'center',
        justifyContent:'space-between'
      }}>
        <Box display={'flex'} alignItems={'center'} >
          <img src={backendUrl+'image/logo/'+img} alt={name} width={40} height={40} />
            <Box ml={3}>
            <Box display={'flex'} alignItems={'center'}>
                <Typography >{symbol}</Typography>
            </Box>
            <Typography sx={{fontStyle:'italic',color:'grey'}}>{name}</Typography>
            </Box>
        </Box>

        <Box sx= {{  display:'flex' }}>
          <Typography mr={3}>
            {value}
          </Typography>
        </Box>
      </Paper>
  )
}
