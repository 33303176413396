import React, { createContext, useEffect, useState } from 'react'
//import axios from 'axios'
import { useNavigate } from 'react-router-dom'
//import axios from 'axios'

export const AuthContext= createContext()

export const AuthContextProvider = ({children}) => {

    const [user,setUser] = useState(null)
    const [token,setToken] = useState(null)
    const [refreshToken,setRefreshToken] = useState(null)
    const [isAuthenticated, setAuthenticated] = useState(null)

    const navigate = useNavigate()

    const handleLogin = (user,token,refreshToken) => {

        setUser(user)
        setToken(token)
        setRefreshToken(refreshToken)
        setAuthenticated(true)

        //save everything in local storage
        localStorage.setItem(
            'userData' , 
            JSON.stringify({
            user,
            isAuthenticated,
            }) 
        )
        localStorage.setItem(
            'authData' , 
            JSON.stringify({
            token,
            refreshToken,
            }) 
        )
        navigate('/')
    }
    const reloadAuthData = (token,refreshToken) => {
        setToken(token)
        setRefreshToken(refreshToken)
    }

    //on reload check if the user is still logged in in server
    const reload = (user,token,refreshToken) => {
        
        setUser(user)
        setToken(token)
        setRefreshToken(refreshToken)
        setAuthenticated(true)

    }

    const handleLogout = () => {
        
           setUser(null)
           setToken(null)
           setRefreshToken(null)
           setAuthenticated(false)
           localStorage.removeItem('userData')
           localStorage.removeItem('authData')
        
       }
     
       
           //when the user reload the page 
           useEffect(()=>{
             const storedUserData = JSON.parse(localStorage.getItem('userData'))
             const storedAuthData = JSON.parse(localStorage.getItem('authData'))
             if(storedUserData && storedAuthData && storedAuthData.token && storedAuthData.refreshToken){
               setAuthenticated(true)
               reload(storedUserData.user, storedAuthData.token,storedAuthData.refreshToken) 
             } else {
                setAuthenticated(false)
             }
             
        }, [])
     
        return (
          <AuthContext.Provider value={{user,token,refreshToken,isAuthenticated,handleLogin,reloadAuthData,handleLogout}}>
              {children}
          </AuthContext.Provider>
        )

}