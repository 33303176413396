import { Box, Button } from '@mui/material'
import React from 'react'
import { useAuth } from '../auth'

export const User = () => {
    const auth = useAuth()
  return (
    <Box>
        <Button variant='contained' onClick={auth.handleLogout}>Deconnexion</Button>
    </Box>
  )
}
