import { Box, Button, CircularProgress, Grid, IconButton, InputAdornment, Paper, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import {ReactComponent as InfoIcon} from '../asset/icon/info.svg'
import { ModalError } from '../component'
import { useAuth } from './useAuth'
import { useNavigate } from 'react-router-dom'
import { backendUrl } from '../utils'
import axios from 'axios'

export const Login = () => {

    const auth = useAuth()
    const user = {}

    const navigate = useNavigate()

    const [error,setError] = useState(false)
    const [isLoading,setLoading] = useState(false)

    const [identifier,setIdentifier] = useState('')
    const [password,setPassword] = useState('')

    //const [errorMessage,setErrorMessage] = useState("Mauvais mot de passe ou Id Mvx identifiant")
    const login = () => {
        if(identifier === '' || password === ''){
            return null
        }
        setLoading(true)

        axios.post(backendUrl+'api/login_check',{
            identifier: identifier,
            password:password
        })
        .then((data)=>{
            user.id='4'
            user.username=identifier
            console.log(data.data)
            auth.handleLogin(user,data.data.token,data.data.refresh_token)
        })
        .catch((e)=>{
            console.log(e)
            setError(true)
            setLoading(false)
        })
        /*setTimeout(()=>{
            setLoading(false)
            if (mvxId === 'mvx' && password==='mvx'){
                user.id='4'
                user.username='feno'
                auth.handleLogin(user,'jhgjhg')
            } else {
                setError(true)
            }
        },2000)*/
    }
    return (
        <Box>
            <ModalError open={error} handleClose={()=>setError(false)} message={"Mauvais mot de passe ou Id Mvx identifiant"}/>
            <Grid container sx={{
                height:'100vh'
            }}>
                {/* pub */}
                <Grid item xs={12} md={6}sx={{
                    display:'flex',
                    flexDirection:'column',
                    justifyContent:'center',
                    alignItems:'center'
                }}>
                    <Box sx={{
                        display:'flex',
                        flexDirection:'column',
                        alignItems:{xs:'center',md:'start'}
                    }}>
                    <Box sx={{width:'fit-content'}}> 
                        <Typography variant='h3'  sx={{
                            fontWeight:'bold',
                            fontSize:{xs:30,md:45},
                            fontFamily:"Quicksand",
                            textAlign:{xs:'center',md:'start'}
                        }}>
                            MVX CHAIN
                        </Typography>
                        <Typography variant='h6'  sx={{
                            fontWeight:'bold',
                            fontFamily:"LT Binary Neue",
                            fontSize:{xs:17,md:25},
                        }}>
                            Votre meilleur exchange cryptos
                        </Typography>

                    </Box>
                    <Typography  mt={3} sx={{
                        color:'#adadad',
                        fontFamily:"Tw Cen MT",
                        px:{xs:2,md:0},
                    }}>
                    Achetez, vendez vos cryptomonnaies chez Mvx Chain suivant le marché en temps réel !<br/> USDT, BTC, TRX, BNB, LTC, CORE, ETH, ...
                    </Typography>
                    </Box>

                </Grid>
                {/* END pub */}

                {/* Login */}
                <Grid item xs={12} md={6} sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:{xs:'start',md:'center'}
                }}>
                    <Paper elevation={1} sx={{
                        
                        width:{xs:'95%', md:'80%'},
                        height:'fit-content',
                        p:{xs:2,sm:4,md:6},
                        boxShadow:6,
                        backgroundColor:'#1e1e1ebb'
                    }}>
                        <Stack spacing={1}
                        >
                            <TextField 
                                label="Email ou Nom d'utilisateur" 
                                fullWidth
                                value={identifier}
                                onChange={(e)=>setIdentifier(e.target.value)}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                            <IconButton>
                                                    <InfoIcon style={{fontSize:10}}/>
                                            </IconButton>
                                        </InputAdornment>,
                                }}/>
                            <TextField label='Mot de passe'
                              fullWidth
                              value={password}
                              onChange={(e)=>setPassword(e.target.value)}
                              type='password'
                              />
                            <Button variant='contained' onClick={login}>
                                { isLoading ? <CircularProgress sx={{color: '#fff', fontSize:5}}/> : "Connexion"}
                            </Button>
                            
                            <Box sx={{
                                display:'flex',
                                justifyContent:'center',
                                paddingTop:2
                                
                            }}>
                                <Button 
                                onClick={()=>navigate('/register')}
                                sx={{
                                    textTransform:'capitalize',
                                    //marginLeft:'auto',
                                    width:'fit-content',
                                }}>
                                    Creer un compte
                                </Button>
                            </Box>
                        </Stack>
                    </Paper>
                </Grid>
                {/* END Login */}
            </Grid>
        </Box>
    )
}
