import React, { useState } from 'react'

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import HomeMaxOutlinedIcon from '@mui/icons-material/HomeMaxOutlined';
import { RiHandCoinLine } from "react-icons/ri";
import { TbArrowsExchange } from "react-icons/tb";
import { PiWallet } from "react-icons/pi";

import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import { Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { pages } from '../utils';

export const ButtonNavigation = () => {
    const [value, setValue] = useState(0)
    const navigate = useNavigate()

    return (

      <Paper sx={{ display:{md:'none'},position: 'fixed', bottom: 0, left: 0, right: 0, backgroundColor:'#1c1c1c' }} elevation={1}>
        
        <BottomNavigation
        showLabels
        value={value}
        sx={{
            backgroundColor: '#1c1c1c',
            px:1,
        }}
        onChange={(event, newValue) => {
            setValue(newValue)
            navigate(pages[newValue].link)
        }}
        >
        <BottomNavigationAction label="Home" icon={<HomeMaxOutlinedIcon />} />
        <BottomNavigationAction label="Cotation" icon={<AutoGraphIcon />} />
        <BottomNavigationAction label="Trade" icon={<TbArrowsExchange size={25}/>} />
        <BottomNavigationAction label="Extra" icon={<RiHandCoinLine size={25}/>} />
        <BottomNavigationAction label="Portefeille" icon={<PiWallet size={23}/>} />
        </BottomNavigation>
        </Paper>
    )
}
