import React from 'react'
import ContentLoader from 'react-content-loader'

export const TwoBarContentLoader = () => {
  return (
    <ContentLoader
        speed={2}
        viewBox="0 0 80 100%"
        backgroundColor="#f3f3f311"
        foregroundColor="#ecebeb22"
        width={'100%'}
        height={'80px'}
            >
        <circle cx="10" cy="20" r="8" /> 
        <rect x="25" y="15" rx="5" ry="5" width="100%" height="10" /> 
        <circle cx="10" cy="50" r="8" /> 
        <rect x="25" y="45" rx="5" ry="5" width="100%" height="10" /> 
    </ContentLoader>
  )
}
