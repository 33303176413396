import axios from 'axios'
import { backendUrl } from './constant'
//import { AuthContext } from './path/to/AuthContext'; // adjust the path as needed
import { useAuth } from '../auth';
import { useEffect } from 'react';

const axiosClient = axios.create({
    baseURL: backendUrl,
})

/*
axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
        if(error.response && error.response.status === 401){
            console.log('make some refresh token here')
        }
        return Promise.reject(error)
    }
)
*/

// Create an instance of axios 
const axiosInstance = axios.create({
  baseURL: backendUrl, // Replace with the correct URL
  headers: {
    'Content-Type': 'application/json',
  },
});

// Function to refresh the token
const refreshAccessToken = async (refreshToken) => {
  try {
    const response = await axios.post(backendUrl+'api/token/refresh', {
            refresh_token: refreshToken
          })
    //(backendUrl+'api/token/refresh', { refreshToken });
    return response.data;
  } catch (error) {
    console.error('Error refreshing token:', error);
    throw error;
  }
};

// Axios interceptor setup
const setupInterceptors = (auth) => {

  axiosInstance.interceptors.request.use(
    (config) => {
      //auth.token
      const storedData = JSON.parse(localStorage.getItem('authData'))
     /* if(storedData && storedData.token && storedData.refreshToken){*/ 
      if (storedData.token) {
        config.headers.Authorization = `Bearer ${storedData.token}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;
      const storedData = JSON.parse(localStorage.getItem('authData'))

      if (error.response?.status === 401 && !originalRequest._retry && storedData && storedData.refreshToken) {
        originalRequest._retry = true;

            console.log('refres')
            //console.log(auth.refreshToken)

          try{
            const response = await axios.post(backendUrl+'api/token/refresh', {
              refresh_token: storedData.refreshToken
            })
          /*  axios.post(backendUrl+'api/token/refresh', {
              refresh_token: auth.refreshToken
          })*/
          //.then((resp)=>{
            const token = response.data.token
            const refreshToken=response.data.refresh_token

            console.log(token,refreshToken)
            auth.reloadAuthData(token,refreshToken)
            localStorage.setItem(
              'authData' , 
              JSON.stringify({
              token:token,
              refreshToken:refreshToken,
              }) 
            )
            // Update the authorization header with the new access token.
            axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            return axiosInstance(originalRequest); // Retry the original request with the new access token.
          //})
          /*.catch((refreshError)=>{
            auth.handleLogout(); // log out if refresh fails
            return Promise.reject(refreshError);
          })*/
          } catch (refreshError) {
           console.log("ato") 
           auth.handleLogout(); // log out if refresh fails
           return Promise.reject(refreshError);
          }
      }

      return Promise.reject(error);
    }
  );
};

// Hook to use the Axios instance
export const useAxios = () => {
  const auth = useAuth()
  useEffect(() => {
    setupInterceptors(auth);
  }, [auth]);
  
  return axiosInstance;
};


export {axiosClient}
